var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
define(["require", "exports", "../../../../model/index", "./player-consumption.tracking", "./video-player-brightcove", "../course-consumption.action"], function (require, exports, index_1, player_consumption_tracking_1, video_player_brightcove_1, course_consumption_action_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var playerSetting = {
        volume: 1,
        playbackRate: 1,
        muted: false,
        captionLanguage: ''
    };
    var playerSettingsUpdateTimeoutId = null;
    var BCPlayerHandler = function () {
        var mPlayer;
        var currentComponent = {};
        var playerPlaceHolderId = 'bcPlayerPlaceHolder';
        var processingChangeVideo = false;
        var stopPlayer = function () {
            if (window.bc && document.getElementById('oudl_video_id')) {
                mPlayer = window === null || window === void 0 ? void 0 : window.bc('oudl_video_id');
                if (mPlayer) {
                    mPlayer.pause();
                }
            }
        };
        var triggerChangeVideo = function () {
            var _a, _b;
            var videojs = (_a = window === null || window === void 0 ? void 0 : window.bc) === null || _a === void 0 ? void 0 : _a.videojs;
            if (videojs) {
                (_b = videojs === null || videojs === void 0 ? void 0 : videojs.players) === null || _b === void 0 ? void 0 : _b.oudl_video_id.trigger('changeVideo');
            }
        };
        var destroyBrightcovePlayer = function () {
            var _a;
            if (document.getElementById('oudl_video_id')) {
                var videojs_1 = (_a = window === null || window === void 0 ? void 0 : window.bc) === null || _a === void 0 ? void 0 : _a.videojs;
                if (videojs_1) {
                    // destroying the brightcove players.
                    videojs_1.players.oudl_video_id.dispose();
                }
            }
        };
        var setUpListeners = function () {
            var brightcovePlayerInst = new video_player_brightcove_1.VideoPlayerBrightcove();
            var _a = player_consumption_tracking_1.PlayerConsumptionTracking.getInstance(brightcovePlayerInst), playerListeners = _a.playerListeners, updatePlayerInst = _a.updatePlayerInst, resetVideoContext = _a.resetVideoContext;
            updatePlayerInst(brightcovePlayerInst);
            resetVideoContext();
            var listeners = playerListeners();
            mPlayer.on('started', listeners.onStarted);
            mPlayer.on('play', listeners.onPlay);
            mPlayer.on('pause', listeners.onPause);
            mPlayer.on('timeupdate', listeners.onTimeUpdate);
            mPlayer.on('changeVideo', listeners.onChangeVideo);
            mPlayer.on('seeked', listeners.onSeeked);
            mPlayer.on('seeking', listeners.onSeeking);
            mPlayer.on('ended', listeners.onEnded);
        };
        var addVolumeChangeEvent = function () {
            mPlayer.on('volumechange', function () {
                var volume = mPlayer.volume();
                var muted = mPlayer.muted();
                playerSetting = __assign(__assign({}, playerSetting), { volume: volume, muted: muted });
                updateUserPlayerSetting();
            });
        };
        var addRateChangeEvent = function () {
            mPlayer.on('ratechange', function () {
                var playbackRate = mPlayer.playbackRate();
                playerSetting = __assign(__assign({}, playerSetting), { playbackRate: playbackRate });
                updateUserPlayerSetting();
            });
        };
        var getSelectedCaptionTrack = function (textTracks) {
            var tracks = textTracks.tracks_;
            var track = tracks.find(function (t) { return t.mode === 'showing'; });
            return track;
        };
        var addCaptionChangeEvent = function () {
            var textTracks = mPlayer.textTracks();
            textTracks.on('change', function () {
                var track = getSelectedCaptionTrack(textTracks);
                var captionLanguage = (track === null || track === void 0 ? void 0 : track.language) || '';
                playerSetting = __assign(__assign({}, playerSetting), { captionLanguage: captionLanguage });
                updateUserPlayerSetting();
            });
        };
        var unFreezePlaylist = function () {
            var bckbone = index_1.Backbone.getInstance();
            var course = bckbone.state.course;
            course.videoLoading = false;
            bckbone.setState({ course: course });
        };
        var loadTranscript = function () {
            var _a, _b;
            if (document.getElementById('Transcript') && currentComponent.transcriptId !== '' && document.getElementById("3p-plugin-target-".concat(currentComponent.transcriptId, "-oytt604c"))) {
                if ((window === null || window === void 0 ? void 0 : window.p3sdk) && document.getElementById('p3Script')) {
                    (_a = document.getElementById('Transcript')) === null || _a === void 0 ? void 0 : _a.removeAttribute('initialized');
                    try {
                        (_b = window === null || window === void 0 ? void 0 : window.p3sdk) === null || _b === void 0 ? void 0 : _b.get(0).interactive_transcripts[0].load_transcript();
                    }
                    catch (ex) {
                        console.error("Error: ".concat(ex));
                    }
                }
                else {
                    var s = document.createElement('script');
                    s.id = 'p3Script';
                    s.src = '//p3.3playmedia.com/p3sdk.current.js';
                    document.body.appendChild(s);
                }
            }
        };
        var playerHandling = function () {
            if (!mPlayer) {
                mPlayer = window === null || window === void 0 ? void 0 : window.bc('oudl_video_id');
            }
            if (mPlayer) {
                try {
                    loadTranscript();
                }
                catch (ex) {
                    console.error('Error while loading transcript: ', ex);
                }
                var playPromise = mPlayer.play();
                if (playPromise !== undefined) {
                    playPromise.then(function (_) {
                        unFreezePlaylist();
                        mPlayer.play();
                    }).catch(function (error) {
                        unFreezePlaylist();
                        console.error('Error calling Play: ', error);
                    });
                }
                mPlayer.trigger('started');
            }
        };
        var setCaptionLanguage = function (captionLanguage) {
            var textTracks = mPlayer.textTracks();
            var tracks = textTracks.tracks_;
            var index = tracks.findIndex(function (track) { return track.language === captionLanguage; });
            if (index > -1) {
                tracks[index].mode = 'showing';
            }
        };
        var stringToHTML = function (str) {
            var domParser = new DOMParser();
            var doc = domParser.parseFromString(str, 'text/html');
            return doc.body.firstChild;
        };
        var setSettingChangeHandler = function () {
            if (mPlayer) {
                addVolumeChangeEvent();
                addRateChangeEvent();
                addCaptionChangeEvent();
            }
        };
        var setPlayerSetting = function () {
            if (mPlayer) {
                var volume_1 = playerSetting.volume, playbackRate_1 = playerSetting.playbackRate, muted_1 = playerSetting.muted, captionLanguage_1 = playerSetting.captionLanguage;
                setTimeout(function () {
                    mPlayer.playbackRate(playbackRate_1);
                    mPlayer.volume(volume_1);
                    mPlayer.muted(muted_1);
                    if (captionLanguage_1) {
                        setCaptionLanguage(captionLanguage_1);
                    }
                }, 2000);
            }
        };
        var getUserPlayerSettings = function () { return __awaiter(void 0, void 0, void 0, function () {
            var backbn, userProfile, _a, user, courseAction;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        backbn = index_1.Backbone.getInstance();
                        userProfile = backbn.state.userProfile;
                        _a = userProfile.user, user = _a === void 0 ? {} : _a;
                        if (user.isGuest)
                            return [2 /*return*/, playerSetting];
                        courseAction = course_consumption_action_1.CourseConsumptionAction.getInstance();
                        return [4 /*yield*/, courseAction.getUserBrightcovePlayerSettings()];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        }); };
        var updateUserPlayerSetting = function () {
            var backbn = index_1.Backbone.getInstance();
            var userProfile = backbn.state.userProfile;
            var _a = userProfile.user, user = _a === void 0 ? {} : _a;
            if (user.isGuest)
                return;
            if (playerSettingsUpdateTimeoutId)
                clearTimeout(playerSettingsUpdateTimeoutId);
            playerSettingsUpdateTimeoutId = setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var courseAction;
                return __generator(this, function (_a) {
                    courseAction = course_consumption_action_1.CourseConsumptionAction.getInstance();
                    courseAction.saveUserBrightcovePlayerSettings(playerSetting);
                    return [2 /*return*/];
                });
            }); }, 2000);
        };
        var ffBkButtons = function () {
            var jumpAmount = 10;
            // Get the element to insert buttons in front of in conrolbar
            var skipForwardElement = document.createElement('div');
            skipForwardElement.id = 'bc-skip-forward-button';
            skipForwardElement.className = 'bc-ff-bb';
            skipForwardElement.title = 'Skip 10 seconds forward';
            skipForwardElement.tabIndex = 0;
            var forwardSpan = document.createElement('span');
            forwardSpan.className = 'bc-ff-bb-span';
            var forwardI = document.createElement('i');
            forwardI.className = 'oj-ux-ico-rotate-cw skip-ff-bb';
            forwardSpan.appendChild(forwardI);
            skipForwardElement.appendChild(forwardSpan);
            skipForwardElement.addEventListener('click', function () {
                var newTime;
                var videoTime = mPlayer.currentTime();
                var videoDuration = mPlayer.duration();
                if (videoTime + jumpAmount <= videoDuration) {
                    newTime = videoTime + jumpAmount;
                }
                else {
                    newTime = videoDuration;
                }
                mPlayer.currentTime(newTime);
            });
            var skipBackwardElement = document.createElement('div');
            skipBackwardElement.id = 'bc-skip-backward-button';
            skipBackwardElement.className = 'bc-ff-bb';
            skipBackwardElement.title = 'Skip 10 seconds backwards';
            skipBackwardElement.tabIndex = 0;
            var backwardSpan = document.createElement('span');
            backwardSpan.className = 'bc-ff-bb-span';
            var backwardI = document.createElement('i');
            backwardI.className = 'oj-ux-ico-rotate-ccw skip-ff-bb';
            backwardSpan.appendChild(backwardI);
            skipBackwardElement.appendChild(backwardSpan);
            skipBackwardElement.addEventListener('click', function () {
                var newTime;
                var videoTime = mPlayer.currentTime();
                if (videoTime >= jumpAmount) {
                    newTime = videoTime - jumpAmount;
                }
                else {
                    newTime = 0;
                }
                mPlayer.currentTime(newTime);
            });
            var playerControlBar = document.getElementsByClassName('vjs-control-bar');
            playerControlBar[0].insertBefore(skipForwardElement, playerControlBar[0].children[2]);
            playerControlBar[0].insertBefore(skipBackwardElement, playerControlBar[0].children[2]);
        };
        var changeVideo = function (htmlElement) {
            var _a, _b, _c, _d, _e, _f, _g;
            var videojs = (_a = window === null || window === void 0 ? void 0 : window.bc) === null || _a === void 0 ? void 0 : _a.videojs;
            if (videojs) {
                // When player is changed to a player with different account id
                if (typeof ((_c = (_b = videojs === null || videojs === void 0 ? void 0 : videojs.players) === null || _b === void 0 ? void 0 : _b.oudl_video_id) === null || _c === void 0 ? void 0 : _c.getAttribute('data-player')) === 'undefined') {
                    return true;
                }
                if (((_e = (_d = videojs === null || videojs === void 0 ? void 0 : videojs.players) === null || _d === void 0 ? void 0 : _d.oudl_video_id) === null || _e === void 0 ? void 0 : _e.getAttribute('data-player')) !== currentComponent.playerDataPlayer) {
                    videojs.players.oudl_video_id.dispose();
                    return true;
                }
                else {
                    // When the video component is selected after non-video component
                    // since after selecting non-video component, #oudl_video_id got deleted, so we have to rerender it again
                    if (!document.getElementById('oudl_video_id')) {
                        (_f = document.getElementById(playerPlaceHolderId)) === null || _f === void 0 ? void 0 : _f.appendChild(htmlElement);
                        window === null || window === void 0 ? void 0 : window.bc(document.getElementById('oudl_video_id')); // initialize the video component, this will populate extra html element for video rendering
                        addPlayerCallback();
                    }
                    else {
                        // when video component is selected after video component, just load the video from catalog
                        mPlayer = window === null || window === void 0 ? void 0 : window.bc('oudl_video_id');
                        (_g = mPlayer.catalog) === null || _g === void 0 ? void 0 : _g.getVideo(currentComponent === null || currentComponent === void 0 ? void 0 : currentComponent.videoId, function (err, video) {
                            var _a;
                            if (!err) {
                                try {
                                    (_a = mPlayer.catalog) === null || _a === void 0 ? void 0 : _a.load(video);
                                    setPlayerSetting();
                                    playerHandling();
                                }
                                catch (ex) {
                                    console.error('error, not able to load video from videojs catalog: ', ex);
                                }
                            }
                        });
                    }
                    return false;
                }
            }
            return true;
        };
        var validateVideoData = function (videoData) {
            return (videoData.playerAccountId && videoData.playerAccountId !== '' &&
                videoData.playerDataPlayer && videoData.playerDataPlayer !== '' &&
                videoData.playerDataEmbed && videoData.playerDataEmbed !== '' &&
                videoData.videoId && videoData.videoId !== '');
        };
        var addBrightcovePlayer = function (videoData) { return __awaiter(void 0, void 0, void 0, function () {
            var playerHTML, htmlElement, addReplacePlayer;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        clearBrightcoveScript();
                        return [4 /*yield*/, getUserPlayerSettings()];
                    case 1:
                        playerSetting = _b.sent();
                        if (!validateVideoData(videoData)) {
                            console.log('provided videoData vlidation failed');
                            return [2 /*return*/];
                        }
                        currentComponent = videoData;
                        playerHTML = '<video-js id="oudl_video_id" data-application-id data-video-id="' + currentComponent.videoId +
                            '" data-account="' + currentComponent.playerAccountId + '" data-player="' + currentComponent.playerDataPlayer +
                            '" data-embed="' + currentComponent.playerDataEmbed + '" class="video-js" controls></video-js>';
                        htmlElement = stringToHTML(playerHTML);
                        // will be set to false by changeVideo if accountId is same
                        if (!processingChangeVideo) {
                            processingChangeVideo = true;
                            addReplacePlayer = changeVideo(htmlElement);
                            if (addReplacePlayer) {
                                // add playerHTML code snippet in case it is not in DOM
                                if (!document.getElementById('oudl_video_id')) {
                                    (_a = document.getElementById(playerPlaceHolderId)) === null || _a === void 0 ? void 0 : _a.appendChild(htmlElement);
                                }
                                initializeBrightcoveScriptFetcher();
                            }
                            processingChangeVideo = false;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var addPlayerCallback = function () {
            var _a, _b, _c;
            // Get reference to the player
            mPlayer = window === null || window === void 0 ? void 0 : window.bc('oudl_video_id');
            if (mPlayer) {
                ffBkButtons();
                var isSafari_1 = ((_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === null || _c === void 0 ? void 0 : _c.indexOf('safari')) > 0;
                // The order in which the events will load - loadstart, loadedmetadata, loadeddata
                // Once loadedmetadata loads, we steup all the events (timeUpdate, seek, ended, etc) so that we can use those in tracking
                mPlayer.one('loadedmetadata', function () {
                    mPlayer.ready(setUpListeners);
                    if (isSafari_1)
                        beginPlayerInitAndCallPlay();
                });
                // loadeddata will run when everything is ready and the video starts playing
                // here we allow the user to use the playlist and
                // handle if there is any overlapping video playback happens
                mPlayer.one('loadeddata', function () {
                    if (!isSafari_1)
                        beginPlayerInitAndCallPlay();
                });
            }
        };
        var beginPlayerInitAndCallPlay = function () {
            setSettingChangeHandler();
            setPlayerSetting();
            playerHandling();
        };
        var initializeBrightcoveScriptFetcher = function () {
            var refParentNode = document.getElementById(playerPlaceHolderId);
            var script = document.createElement('script');
            if (!(window === null || window === void 0 ? void 0 : window.fetchAndInitialize)) {
                script.onload = function () { return __awaiter(void 0, void 0, void 0, function () {
                    var fetchAndInitialize;
                    return __generator(this, function (_a) {
                        fetchAndInitialize = window === null || window === void 0 ? void 0 : window.fetchAndInitialize;
                        if (fetchAndInitialize !== undefined) {
                            fetchAndInitialize(currentComponent.playerAccountId, currentComponent.playerDataPlayer + '_' + currentComponent.playerDataEmbed, addPlayerCallback);
                        }
                        return [2 /*return*/];
                    });
                }); };
                script.onerror = function () { console.error('Player script could not be downloaded.'); };
                script.async = true;
                script.src = '/bc_helper.js';
                refParentNode === null || refParentNode === void 0 ? void 0 : refParentNode.appendChild(script);
            }
            else {
                var fetchAndInitialize = window === null || window === void 0 ? void 0 : window.fetchAndInitialize;
                fetchAndInitialize(currentComponent.playerAccountId, currentComponent.playerDataPlayer + '_' + currentComponent.playerDataEmbed, addPlayerCallback);
            }
        };
        function clearBrightcoveScript() {
            var _a, _b;
            if (!((_b = (_a = window.bc) === null || _a === void 0 ? void 0 : _a.videojs) === null || _b === void 0 ? void 0 : _b.players))
                return;
            var players = window.bc.videojs.players;
            // Clear just if another instance was launched
            var shouldClear = false;
            Object.keys(players).forEach(function (player) {
                if (player !== 'oudl_video_id') {
                    delete players[player];
                    shouldClear = true;
                }
            });
            if (!shouldClear)
                return;
            // Remove dynamically added script
            var script = document.querySelector('script[src="/bc_helper.js"]');
            if (script) {
                script.remove();
            }
            // Clear up any references to global functions or variables created by the script
            if (window === null || window === void 0 ? void 0 : window.bc) {
                window === null || window === void 0 ? true : delete window.bc;
            }
        }
        return { addBrightcovePlayer: addBrightcovePlayer, stopPlayer: stopPlayer, destroyBrightcovePlayer: destroyBrightcovePlayer, triggerChangeVideo: triggerChangeVideo };
    };
    exports.default = BCPlayerHandler;
});
