define(["require", "exports", "preact", "../../../shared.mod/index"], function (require, exports, preact_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function EnrolledLiveSessionsCarousel(_a) {
        var enrolledLiveSessions = _a.enrolledLiveSessions;
        if (!enrolledLiveSessions.length) {
            return (0, preact_1.h)(preact_1.Fragment, null, "No scheduled live session");
        }
        return ((0, preact_1.h)(index_1.TileboxCarousel, { title: "My Live Sessions", hits: {
                hits: enrolledLiveSessions.map(function (_a) {
                    var id = _a.id, name = _a.name, startDate = _a.startDate, timeZone = _a.timeZone, duration = _a.duration, parentContainerId = _a.parentContainerId, parentContainerType = _a.parentContainerType, grandparentContainerIds = _a.grandparentContainerIds;
                    return ({
                        _source: {
                            isComponent: true,
                            'dl.item.id': parentContainerId,
                            'dl.item.name': name,
                            'dl.item.description': '',
                            'dl.item.type': 'Live Session',
                            'dl.item.legacyType': parentContainerType,
                            'dl.item.offerings': grandparentContainerIds,
                            'dl.pillar_names': [''],
                            'dl.lses.id': [id],
                            'dl.lses.dt1': [startDate],
                            'dl.lses.time_zone': [timeZone],
                            'dl.lses.duration': [duration]
                        }
                    });
                })
            }, handleExploreMore: false }));
    }
    exports.default = EnrolledLiveSessionsCarousel;
});
