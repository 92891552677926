define(["require", "exports", "preact", "react-i18next"], function (require, exports, preact_1, react_i18next_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var ContSectionContent = function (props) {
        var t = (0, react_i18next_1.useTranslation)('userHistory').t;
        var duration = props.duration, isListView = props.isListView;
        return ((0, preact_1.h)("div", { style: isListView ? { marginRight: '45px' } : {} },
            (0, preact_1.h)("div", { className: "oj-sm-only-hide oj-typography-body-xs oj-text-color-secondary oj-sp-card-footer-ellipsis" }, t('Duration')),
            (0, preact_1.h)("div", { className: "oj-typography-body-sm oj-text-color-primary oj-sp-card-footer-ellipsis" }, duration)));
    };
    exports.default = ContSectionContent;
});
