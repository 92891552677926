define(["require", "exports", "preact", "react", "../../../../../model/index", "./styles.css"], function (require, exports, preact_1, react_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function AskTheInstructorIframeDrawer(props) {
        var onClose = props.onClose, showDrawer = props.showDrawer;
        var _a = (0, react_1.useState)(false), loadedIframe = _a[0], setLoadedIframe = _a[1];
        var frontend = index_1.Backbone.getInstance().state.learnerRoleProfile.frontend;
        var openChangeHandler = function (event) {
            if (event.detail.updatedFrom === 'internal') {
                onClose();
            }
        };
        var onLoadIframe = function () {
            var iframe = document.getElementById('AskTheInstructorIframe');
            if (iframe) {
                setLoadedIframe(true);
                iframe.focus();
            }
        };
        return ((0, preact_1.h)("oj-drawer-popup", { class: 'ask-instructor-width', "auto-dismiss": "none", edge: "end", opened: showDrawer, onopenedChanged: openChangeHandler, closeGesture: "none" },
            (0, preact_1.h)("div", { className: "ask-instructor-iframe-drawer ask-instructor-full-height" },
                (0, preact_1.h)("div", null,
                    (0, preact_1.h)("div", { className: "drawer-header oj-sm-padding-8x-start oj-sm-padding-8x-end oj-sm-padding-4x-top" },
                        (0, preact_1.h)("h6", { className: "oj-typography-subheading-md" }, "Ask The Instructor"),
                        (0, preact_1.h)("oj-button", { id: "endButtonCloserAskTheInstructorDrawer", display: "icons", chroming: "borderless", onojAction: function () { onClose(); } },
                            (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-close" }),
                            "Close")),
                    (0, preact_1.h)("hr", null)),
                (0, preact_1.h)("iframe", { title: 'iframe-ask-the-instructor-iframe', id: "AskTheInstructorIframe", style: {
                        width: '100%',
                        height: '100%',
                        display: loadedIframe ? 'block' : 'none'
                    }, onLoad: onLoadIframe, src: frontend.askTheInstructorDrawerArticleCourseLink.default }),
                (0, preact_1.h)("div", { style: { position: 'absolute', top: '50%', left: '50%', marginLeft: '-48px', marginTop: '-48px', display: !loadedIframe ? 'block' : 'none' } },
                    (0, preact_1.h)("oj-progress-circle", { size: "lg", value: -1 })))));
    }
    exports.default = AskTheInstructorIframeDrawer;
    ;
});
