define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.VideoPlayerKaltura = void 0;
    var VideoPlayerKaltura = /** @class */ (function () {
        function VideoPlayerKaltura() {
            this.allSeekedEvent = [];
            this.lastSeekedPosition = -1;
            this.lastPosition = 0;
            this.init();
            this.onTimeUpdateCallback(function () { });
            this.onSeekingCallback(function () { });
        }
        ;
        VideoPlayerKaltura.prototype.init = function () {
            this.mPlayer = window.KalturaPlayer.getPlayers().ktPlayerPlaceHolder;
            return this;
        };
        ;
        VideoPlayerKaltura.prototype.getCurrentTime = function () {
            return Math.floor(this.mPlayer.currentTime);
        };
        ;
        VideoPlayerKaltura.prototype.getExactCurrentTime = function () {
            return this.mPlayer.currentTime;
        };
        VideoPlayerKaltura.prototype.getLastPosition = function () {
            return parseInt(this.lastPosition.toFixed(0));
        };
        ;
        VideoPlayerKaltura.prototype.getDuration = function () {
            return parseInt(this.mPlayer.duration.toFixed(0));
        };
        ;
        VideoPlayerKaltura.prototype.getLastSeekedEvents = function () {
            return { seekStart: -1, seekEnd: -1 };
        };
        ;
        VideoPlayerKaltura.prototype.getLastPlaybackRate = function () {
            return this.mPlayer.playbackRate;
        };
        ;
        VideoPlayerKaltura.prototype.pause = function () {
            this.mPlayer.pause();
        };
        ;
        VideoPlayerKaltura.prototype.setCurrentTime = function (position) {
            this.mPlayer.currentTime = parseInt(Number(position).toFixed(1));
        };
        ;
        VideoPlayerKaltura.prototype.onStartedCallback = function (callback) {
            this.mPlayer.addEventListener('STARTED', function (event) {
                callback();
            });
        };
        ;
        VideoPlayerKaltura.prototype.onPlayCallback = function (callback) {
            this.mPlayer.addEventListener(this.mPlayer.Event.Core.PLAY, function (event) {
                callback();
            });
        };
        ;
        VideoPlayerKaltura.prototype.onTimeUpdateCallback = function (callback) {
            var _this = this;
            this.mPlayer.addEventListener(this.mPlayer.Event.Core.TIME_UPDATE, function (event) {
                _this.lastPosition = _this.mPlayer.currentTime;
                callback();
            });
        };
        VideoPlayerKaltura.prototype.onSeekedCallback = function (callback) {
            this.mPlayer.addEventListener(this.mPlayer.Event.Core.SEEKED, function (seekedTime) {
                callback();
            });
        };
        ;
        VideoPlayerKaltura.prototype.onSeekingCallback = function (callback) {
            this.mPlayer.addEventListener(this.mPlayer.Event.Core.SEEKING, function (seekedTime) {
                callback();
            });
        };
        ;
        VideoPlayerKaltura.prototype.onPauseCallback = function (callback) {
            this.mPlayer.addEventListener(this.mPlayer.Event.Core.PAUSE, function (event) {
                callback();
            });
        };
        ;
        VideoPlayerKaltura.prototype.onWaitingCallback = function (callback) {
            this.mPlayer.addEventListener(this.mPlayer.Event.Core.WAITING, function (event) {
                callback();
            });
        };
        ;
        return VideoPlayerKaltura;
    }());
    exports.VideoPlayerKaltura = VideoPlayerKaltura;
    ;
});
