define(["require", "exports", "preact"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function AccessSplitLab(props) {
        var _a = props.textBody, textBody = _a === void 0 ? '' : _a, _b = props.splitScreenUrl, splitScreenUrl = _b === void 0 ? '' : _b, _c = props.showReleaseLab, showReleaseLab = _c === void 0 ? false : _c, _d = props.onReleaseLab, onReleaseLab = _d === void 0 ? function () { } : _d, _e = props.showExtendLab, showExtendLab = _e === void 0 ? false : _e, _f = props.onExtendLab, onExtendLab = _f === void 0 ? false : _f;
        var handleLaunchLab = function () {
            window.open(splitScreenUrl, 'blank');
        };
        return ((0, preact_1.h)("div", { className: "oj-bg-success-10 oj-flex-item oj-divider-bottom" },
            (0, preact_1.h)("div", { className: "oj-flex-bar oj-sm-padding-4x" },
                (0, preact_1.h)("div", { className: "oj-flex-bar-start oj-sm-margin-2x-end" },
                    (0, preact_1.h)("div", { className: "oj-icon-color-success oj-ux-ico-beaker oj-ux-icon-size-4x", style: { marginTop: '2px' } })),
                (0, preact_1.h)("div", { className: "oj-flex-bar-middle oj-flex oj-sm-flex-direction-column" },
                    (0, preact_1.h)("div", { className: "oj-flex-item oj-text-color-success oj-typography-body-md oj-typography-bold" }, "Access Your Lab"),
                    (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-padding-2x-top" },
                        (0, preact_1.h)("span", { className: "oj-typography-body-sm" }, textBody)),
                    (0, preact_1.h)("div", { className: "oj-flex oj-flex-item oj-sm-flex-items-initial" },
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-padding-2x-top oj-sm-margin-5x-end" },
                            (0, preact_1.h)("oj-button", { chroming: "callToAction", class: "oj-button-sm", onojAction: function () { return handleLaunchLab(); } }, "Launch Lab")),
                        showReleaseLab &&
                            (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-padding-2x-top oj-sm-margin-5x-end" },
                                (0, preact_1.h)("oj-button", { chroming: "outlined", class: "oj-button-sm", onojAction: function () { return onReleaseLab(); } }, "Release Lab")),
                        showExtendLab &&
                            (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-padding-2x-top oj-sm-margin-5x-end" },
                                (0, preact_1.h)("oj-button", { chroming: "outlined", class: "oj-button-sm", onojAction: function () { return onExtendLab(); } }, "Extend Lab")))))));
    }
    exports.default = AccessSplitLab;
});
