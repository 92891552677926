define(["require", "exports", "preact", "ojs/ojbutton"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var ResumePBTExam = function (props) {
        var handleResumePbtExam = props.handleResumePbtExam;
        return ((0, preact_1.h)("div", { className: "oj-bg-warning-20 oj-sm-padding-6x-bottom oj-sm-only-padding-6x-top oj-sm-only-padding-4x-horizontal oj-md-only-padding-2x-horizontal oj-md-only-padding-6x-top oj-lg-padding-12x-horizontal oj-lg-padding-10x-top" },
            (0, preact_1.h)("div", { className: "oj-flex oj-sm-flex-items-initial" },
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-padding-2x-horizontal" },
                    (0, preact_1.h)("span", { className: "oj-icon-color-warning oj-ux-ico-warning-s oj-ux-icon-size-6x", role: "img", "aria-label": "warning" })),
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-padding-2x-horizontal oj-sm-only-width-full" },
                    (0, preact_1.h)("h5", { className: "oj-typography-heading-xs oj-text-color-warning" }, "Your exam is incomplete"))),
            (0, preact_1.h)("p", { className: 'oj-sm-padding-2x-horizontal' },
                "We regret there was an issue with your exam session. Your proctor has re-enabled your exam ",
                (0, preact_1.h)("br", null),
                " session. Please proceed with resuming your exam"),
            (0, preact_1.h)("div", { className: "oj-flex oj-sm-margin-8x-top" },
                (0, preact_1.h)("div", { className: "oj-sm-padding-4x oj-panel oj-sm-margin-2x-bottom oj-sm-only-width-full" },
                    (0, preact_1.h)("div", { className: "" },
                        (0, preact_1.h)("div", { className: "oj-flex-bar-middle" },
                            (0, preact_1.h)("div", null,
                                (0, preact_1.h)("div", { className: "resume-pbt-exam" },
                                    (0, preact_1.h)("span", { className: "oj-icon-color-info oj-typography-subheading-md" }, "Complete your Exam"),
                                    (0, preact_1.h)("br", null),
                                    (0, preact_1.h)("oj-button", { chroming: "callToAction", class: "oj-button-sm", id: "resumePBTExam", "aria-label": "Resume your exam", onojAction: function () { return handleResumePbtExam(); }, key: "resumePBTExam" }, 'Resume your exam'))),
                            (0, preact_1.h)("div", null,
                                (0, preact_1.h)("div", { className: "timeLiteral oj-flex-bar-start oj-sm-margin-8x-horizontal oj-sm-align-self-center" },
                                    (0, preact_1.h)("div", { className: "pbt-error-warning-circle" },
                                        (0, preact_1.h)("span", { className: "oj-ux-ico-replay" }))))))))));
    };
    exports.default = ResumePBTExam;
});
