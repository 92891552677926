define(["require", "exports", "preact", "../../../../../model/index"], function (require, exports, preact_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var AboutInstructorComponent = function (props) {
        if (props === void 0) { props = {}; }
        var backbone = index_1.Backbone.getInstance();
        var liveSession = backbone.state.liveSession;
        var instructorDetails = liveSession.sessionDetails.instructorDetails;
        return ((0, preact_1.h)("div", { className: "oj-flex-item oj-sm-margin-4x-top oj-sm-margin-8x-bottom" },
            (0, preact_1.h)("h3", { className: "oj-typography-heading-xs" }, 'About the Instructor'),
            (0, preact_1.h)("div", { className: "oj-flex oj-flex-item oj-sm-flex-direction-row" },
                (0, preact_1.h)("div", { className: "oj-flex-item" },
                    (0, preact_1.h)("oj-list-view", { id: "listview", "aria-label": "list with static data", class: "oj-listview-item-padding-off oj-sm-padding-4x-bottom" },
                        (0, preact_1.h)("ul", null,
                            (0, preact_1.h)("li", { id: "chrisblack", className: "instructor-name-section" },
                                (0, preact_1.h)("oj-list-item-layout", null,
                                    (0, preact_1.h)("span", { className: "oj-typography-body-md oj-text-color-primary" }, instructorDetails === null || instructorDetails === void 0 ? void 0 : instructorDetails.name),
                                    (instructorDetails === null || instructorDetails === void 0 ? void 0 : instructorDetails.imgUrl) &&
                                        (0, preact_1.h)("oj-avatar", { slot: "leading", size: "xs", src: instructorDetails === null || instructorDetails === void 0 ? void 0 : instructorDetails.imgUrl }),
                                    (0, preact_1.h)("span", { slot: "secondary", className: "oj-typography-body-xs oj-text-color-secondary" }, instructorDetails === null || instructorDetails === void 0 ? void 0 : instructorDetails.title))),
                            (0, preact_1.h)("span", { className: "oj-typography-body-sm oj-text-color-primary" }, instructorDetails === null || instructorDetails === void 0 ? void 0 : instructorDetails.bio)))))));
    };
    exports.default = AboutInstructorComponent;
});
