var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "preact", "preact/hooks", "lodash", "./styles.css", "ojs/ojprogress-circle"], function (require, exports, preact_1, hooks_1, _) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    _ = __importStar(_);
    var TileContentCollapsed = function (props) {
        if (props === void 0) { props = {}; }
        var node = props.node, clickViewDetails = props.clickViewDetails, int2Duration = props.int2Duration;
        var progressClass = (node === null || node === void 0 ? void 0 : node.totalProgress) > 0 ? ((node === null || node === void 0 ? void 0 : node.totalProgress) === 100 ? 'lp-page-container-completed' : 'lp-page-container-in-progress') : '';
        progressClass = progressClass || ((node === null || node === void 0 ? void 0 : node.completed) === 'Y' ? 'lp-page-container-completed' : '');
        var containerIdPrefix = 'ilpExam';
        var _a = (0, hooks_1.useState)('Get Started'), buttonText = _a[0], setButtonText = _a[1];
        var _b = (0, hooks_1.useState)('Not Registered'), registrationStatus = _b[0], setRegistrationStatus = _b[1];
        var processNode = function (node) {
            var eStatus = node.enrollment_status ? node.enrollment_status.toLowerCase() : null;
            if (eStatus === 'y') {
                setRegistrationStatus('Registered');
                setButtonText('View Details');
            }
            else if (eStatus === 'dp') {
                setRegistrationStatus('Passed!');
                setButtonText('View Details');
            }
            else {
                setRegistrationStatus('Not Registered');
                setButtonText('Register Now');
            }
            // Handle exam status (Pass/Failed)
            if (node.certStatus && node.certStatus.toLowerCase() === 'passed') {
                setRegistrationStatus('Passed!');
                setButtonText('View Details');
            }
        };
        (0, hooks_1.useEffect)(function () {
            if (registrationStatus === 'Not Registered') {
                setButtonText('Register Now');
            }
        }, [registrationStatus]);
        (0, hooks_1.useEffect)(function () {
            if (node) {
                processNode(node);
            }
        }, [node]);
        var examStatusComponent = ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)("div", { className: "oj-sm-5" },
                (0, preact_1.h)("span", { className: 'oj-lg-padding-4x-start' },
                    (0, preact_1.h)("span", { id: "".concat(containerIdPrefix, "registrationStatus-").concat(node === null || node === void 0 ? void 0 : node.id), className: "oj-badge oj-badge-subtle" }, registrationStatus))),
            (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Button-").concat(node === null || node === void 0 ? void 0 : node.id), className: "oj-sm-7 oj-text-right" },
                (0, preact_1.h)("oj-button", { class: "oj-button-md", chroming: "callToAction", id: "scheduleExam-".concat(node === null || node === void 0 ? void 0 : node.id), onojAction: function (e) { e.preventDefault(); clickViewDetails(node); } }, buttonText))));
        return ((0, preact_1.h)("div", { className: "oj-sm-flex-wrap-nowrap oj-flex11 oj-sp-expandable-list-item-header oj-sm-padding-4x-start oj-sm-padding-2x-end oj-sm-padding-3x-vertical oj-collapsible-header oj-sp-expandable-list-item-header-sticky" }, 
        /* @ts-expect-error */
        (0, preact_1.h)("oj-sp-list-item-template", { class: "oj-sm-12 oj-sp-expandable-list-item-nav-text oj-complete" }, (0, preact_1.h)("div", { className: "lp-page-component-box oj-sm-justify-content-space-between oj-sm-align-items-center oj-flex11 lp-page-component lp-page-comp-ilp-exam ".concat(progressClass) },
            (0, preact_1.h)("div", { className: "oj-flex oj-sm-align-items-center oj-position-relative oj-sm-only-padding-6x-bottom" },
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sp-list-item-template-primary-col oj-md-order-2 oj-sm-12 oj-lg-8" },
                    (0, preact_1.h)("div", { className: "oj-flex oj-md-flex-wrap-nowrap oj-sp-list-item-template-column-0" },
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-md-flex-1 oj-sp-list-item-template-flex-shrink oj-sp-list-item-template-main-texts-container" },
                            (0, preact_1.h)("oj-list-item-layout", { class: "oj-sp-list-item-template-list-item-layout oj-complete" },
                                (0, preact_1.h)("div", { className: "oj-listitemlayout-grid" },
                                    (0, preact_1.h)("div", { className: "oj-listitemlayout-textslots" },
                                        (0, preact_1.h)("div", null,
                                            (0, preact_1.h)("div", { className: "oj-flex oj-sm-flex-direction-column" },
                                                (0, preact_1.h)("div", { className: "oj-sp-list-item-template-overline-text oj-flex-item" },
                                                    (0, preact_1.h)("span", { className: "oj-typography-body-xs oj-text-color-secondary oj-typography-bold" }, 'Online Certification Exam')),
                                                (0, preact_1.h)("span", { className: "oj-sp-list-item-template-primary-text oj-flex-item oj-typography-body-md oj-text-color-primary oj-sm-padding-1x-bottom" },
                                                    (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Name-").concat(node === null || node === void 0 ? void 0 : node.id), onClick: typeof (node === null || node === void 0 ? void 0 : node.moocCount) === 'undefined' ? function () { clickViewDetails(node); } : undefined, className: "oj-text-color-primary oj-typography-subheading-xs oj-typography-bold", dangerouslySetInnerHTML: { __html: _.unescape(node === null || node === void 0 ? void 0 : node.name) || '' } })))),
                                        (0, preact_1.h)("div", null,
                                            (0, preact_1.h)("div", { className: "oj-flex-item oj-flex oj-sp-list-item-template-badge-top-container oj-sp-list-item-template-md-badge-fixed " },
                                                (0, preact_1.h)("div", { className: "oj-sp-list-item-template-badge-container" }))),
                                        (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Duration-").concat(node === null || node === void 0 ? void 0 : node.id), className: 'lp-page-course-duration oj-text-left' }, (node === null || node === void 0 ? void 0 : node.duration) && (0, preact_1.h)("span", { className: "oj-flex-item oj-typography-body-xs oj-text-color-secondary oj-typography-bold" }, int2Duration((node === null || node === void 0 ? void 0 : node.duration) || 0))),
                                        (0, preact_1.h)("div", { className: 'oj-sm-margin-2x-bottom oj-sm-margin-2x-top oj-typography-body-sm' },
                                            (0, preact_1.h)("span", null, "You must register, attend and pass this exam to receive your official credential for this certification.")))))))),
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sp-list-item-template-primary-col oj-md-order-3 oj-sm-12 oj-lg-4" },
                    (0, preact_1.h)("div", { className: "oj-flex oj-md-flex-wrap-nowrap oj-sp-list-item-template-column-0" },
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-md-flex-1 oj-sp-list-item-template-flex-shrink oj-sp-list-item-template-main-texts-container" },
                            (0, preact_1.h)("div", { className: "oj-listitemlayout-grid" },
                                (0, preact_1.h)("div", { className: "oj-listitemlayout-textslots oj-sm-justify-content-space-between oj-sm-align-items-center oj-flex" }, node.isLpUserDataLoaded
                                    ? examStatusComponent
                                    : (0, preact_1.h)("oj-progress-circle", { size: "sm", value: -1 })))))))) /* @ts-expect-error */)));
    };
    function ILPExamTileBox(props) {
        if (props === void 0) { props = {}; }
        var node = props.node, offeringId = props.offeringId, isFavorite = props.isFavorite, clickViewDetails = props.clickViewDetails, int2Duration = props.int2Duration;
        return ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)(TileContentCollapsed, { node: node, offeringId: offeringId, isFavorite: isFavorite, clickViewDetails: clickViewDetails, int2Duration: int2Duration })));
    }
    exports.default = ILPExamTileBox;
});
