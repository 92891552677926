define(["require", "exports", "preact", "ojs/ojbutton"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var AddPrivateEventButton = function (_a) {
        var setIsPrivateEvent = _a.setIsPrivateEvent, isSlotsLoading = _a.isSlotsLoading;
        return ((0, preact_1.h)("div", { className: "oj-flex-item oj-sm-padding-2x-horizontal oj-sm-only-margin-3x-bottom" },
            (0, preact_1.h)("oj-button", { chroming: "outlined", class: "oj-button-lg", id: "addPrivateEvent", "aria-label": "Add Private Event Code", onojAction: function () { return setIsPrivateEvent(true); }, key: "addPrivateEventCode", disabled: isSlotsLoading },
                'Registering for a Private Event? Enter Code ',
                (0, preact_1.h)("span", { slot: "endIcon", className: "oj-ux-ico-plus" }))));
    };
    exports.default = AddPrivateEventButton;
});
