define(["require", "exports", "preact", "ojs/ojprogress-circle", "./loader.css"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var Loader = function (_a) {
        var noEmptyContainer = _a.noEmptyContainer;
        return ((0, preact_1.h)(preact_1.Fragment, null,
            !noEmptyContainer && (0, preact_1.h)("div", { className: "emptyContainer" }),
            (0, preact_1.h)("div", { className: "pageLoader" },
                (0, preact_1.h)("div", { style: { position: 'absolute', top: '50%', left: '50%', marginLeft: '-48px', marginTop: '-48px' } },
                    (0, preact_1.h)("oj-progress-circle", { size: "lg", value: -1 })))));
    };
    exports.default = Loader;
});
