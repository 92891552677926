define(["require", "exports", "preact"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.TreeNode = void 0;
    var TreeNode = function (_a) {
        var node = _a.node, setNowPage = _a.setNowPage, totalPage = _a.totalPage, twoPageShow = _a.twoPageShow, setBookmarkShow = _a.setBookmarkShow, setClickMobileNavigate = _a.setClickMobileNavigate;
        var items = node.items, title = node.title, pageIndex = node.pageIndex;
        var handleClick = function () {
            setNowPage(pageIndex + 1);
            // setContinousScroll(false);
            if (window.innerWidth < 800 && window.innerHeight < 1050) {
                // It's likely a mobile device
                setBookmarkShow(false);
                setClickMobileNavigate(function (prev) { return !prev; });
            }
        };
        return ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)("li", { className: "oj-treeview-item oj-treeview-leaf", role: "treeitem" },
                (0, preact_1.h)("div", { className: "oj-treeview-item-content", style: "line-height: none;" },
                    (0, preact_1.h)("span", { className: "oj-treeview-spacer", style: "" }),
                    (0, preact_1.h)("a", { className: "oj-treeview-item-text oj-line-clamp-2 oj-typography-body-xs", onClick: handleClick }, title)),
                (items.length > 0)
                    ? (0, preact_1.h)("ul", { className: "oj-treeview-list", role: "group" }, items.map(function (item, index) {
                        return (0, preact_1.h)(exports.TreeNode, { key: index, node: item, totalPage: totalPage, twoPageShow: twoPageShow, setNowPage: setNowPage });
                    }))
                    : '')));
    };
    exports.TreeNode = TreeNode;
});
