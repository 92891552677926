define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.VideoPlayerBrightcove = void 0;
    var VideoPlayerBrightcove = /** @class */ (function () {
        function VideoPlayerBrightcove() {
            this.allSeekedEvent = [];
            this.lastSeekedPosition = -1;
            this.lastPosition = 0;
            this.init();
            this.onTimeUpdateCallback(function () { });
            this.onSeekingCallback(function () { });
        }
        ;
        VideoPlayerBrightcove.prototype.init = function () {
            this.mPlayer = window.bc('oudl_video_id');
            return this;
        };
        ;
        VideoPlayerBrightcove.prototype.getCurrentTime = function () {
            return Math.floor(this.mPlayer.currentTime());
        };
        ;
        VideoPlayerBrightcove.prototype.getExactCurrentTime = function () {
            return this.mPlayer.currentTime();
        };
        VideoPlayerBrightcove.prototype.getLastPosition = function () {
            return this.lastPosition;
        };
        ;
        VideoPlayerBrightcove.prototype.getDuration = function () {
            return this.mPlayer.duration();
        };
        ;
        VideoPlayerBrightcove.prototype.getLastSeekedEvents = function () {
            var allSeekEvents = this.mPlayer.seekEvents.all();
            var seek = allSeekEvents.length > 0
                ? allSeekEvents[allSeekEvents.length - 1]
                : {
                    seekStart: this.lastPosition,
                    seekEnd: this.getCurrentTime()
                };
            return seek;
        };
        ;
        VideoPlayerBrightcove.prototype.getLastPlaybackRate = function () {
            return this.mPlayer.cache_.lastPlaybackRate;
        };
        ;
        VideoPlayerBrightcove.prototype.pause = function () {
            this.mPlayer.pause();
        };
        ;
        VideoPlayerBrightcove.prototype.setCurrentTime = function (position) {
            this.mPlayer.currentTime(Number(position).toFixed(1));
        };
        ;
        VideoPlayerBrightcove.prototype.onPlayCallback = function (callback) {
            this.mPlayer.on('play', function (event) {
                callback();
            });
        };
        ;
        VideoPlayerBrightcove.prototype.onTimeUpdateCallback = function (callback) {
            var _this = this;
            this.mPlayer.on('timeupdate', function (event) {
                _this.lastPosition = _this.getCurrentTime();
                if (_this.lastSeekedPosition >= 0) {
                    _this.allSeekedEvent.push({ seekStart: _this.lastSeekedPosition, seekEnd: _this.lastPosition });
                    _this.lastSeekedPosition = -1;
                }
                callback();
            });
        };
        VideoPlayerBrightcove.prototype.onSeekedCallback = function (callback) {
            this.mPlayer.on('seeked', function (seekedTime) {
                callback();
            });
        };
        ;
        VideoPlayerBrightcove.prototype.onSeekingCallback = function (callback) {
            var _this = this;
            this.mPlayer.on('seeking', function (seekedTime) {
                _this.lastSeekedPosition = _this.getCurrentTime();
                callback();
            });
        };
        ;
        VideoPlayerBrightcove.prototype.onPauseCallback = function (callback) {
            this.mPlayer.on('pause', function (event) {
                callback();
            });
        };
        ;
        VideoPlayerBrightcove.prototype.onWaitingCallback = function (callback) {
            this.mPlayer.on('waiting', function (event) {
                callback();
            });
        };
        ;
        return VideoPlayerBrightcove;
    }());
    exports.VideoPlayerBrightcove = VideoPlayerBrightcove;
    ;
});
