define(["require", "exports", "preact", "../../../../model/index", "../../../shared.mod/index", "../../../shared.mod/enums/index"], function (require, exports, preact_1, index_1, index_2, index_3) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function MyEventLearning() {
        var els = (index_1.Backbone.getInstance().state || {}).els;
        var elsSubscriptions = els.subscriptions;
        if (!elsSubscriptions.length) {
            return ((0, preact_1.h)(preact_1.Fragment, null));
        }
        return ((0, preact_1.h)(index_2.TileboxCarousel, { title: "My Event Learning", hits: { hits: elsSubscriptions }, expandedMode: true, cardType: index_3.CardType.ElsEventCard }));
    }
    exports.default = MyEventLearning;
});
