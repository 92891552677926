var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
define(["require", "exports", "preact", "preact/hooks", "./styles.css", "ojs/ojtreeview", "ojs/ojbutton"], function (require, exports, preact_1, hooks_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    // import * as pdfjsLib from 'pdfjs-dist/build/pdf';
    var ThumbnailComponent = function (props) {
        var _a = (0, hooks_1.useState)(1), start = _a[0], setStart = _a[1];
        var _b = (0, hooks_1.useState)(3), end = _b[0], setEnd = _b[1];
        var _c = (0, hooks_1.useState)(false), renderFlag = _c[0], setRenderFlag = _c[1];
        (0, hooks_1.useEffect)(function () {
            // pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/build/pdf.worker.js';
        }, []);
        var ekitGetObject = function (start, end) { return __awaiter(void 0, void 0, void 0, function () {
            function createBufferPdf() {
                return __awaiter(this, void 0, void 0, function () {
                    var mergedPdf, i, options;
                    return __generator(this, function (_a) {
                        mergedPdf = props.streamPdfDistBytes || [];
                        for (i = start - 1; i < end; i++) {
                            options = {
                                data: props.pdfBufferArray[i],
                                verbosity: 0
                            };
                            /* await pdfjsLib.getDocument(options)
                              .promise.then(function (pdfDoc: any) {
                                mergedPdf.push(pdfDoc);
                              }); */
                        }
                        props.setStreamPdfDistBytes(__spreadArray([], mergedPdf, true));
                        displayThumnail(start, end);
                        return [2 /*return*/];
                    });
                });
            }
            function downloadChunks(i) {
                return __awaiter(this, void 0, void 0, function () {
                    var bufferArray, url, responseTwo, buffer;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                bufferArray = props.pdfBufferArray;
                                url = "".concat(props.homeUrl, "/").concat(props.ekitId, "/").concat(i, ".pdf");
                                return [4 /*yield*/, fetch(url, { method: 'GET' })];
                            case 1:
                                responseTwo = _a.sent();
                                return [4 /*yield*/, responseTwo.arrayBuffer()];
                            case 2:
                                buffer = _a.sent();
                                bufferArray[i - 1] = buffer;
                                props.setPdfBufferArray(bufferArray);
                                bufferArray = undefined; // unset
                                return [4 /*yield*/, new Promise(function (resolve, reject) {
                                        resolve(props.pdfBufferArray);
                                    })];
                            case 3:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            }
            var promises, i, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setRenderFlag(true);
                        promises = [];
                        for (i = start; i <= end; i++) {
                            if (props.streamPdfDistBytes[i]) {
                                continue;
                            }
                            else {
                                promises.push(downloadChunks(i));
                            }
                        }
                        return [4 /*yield*/, Promise.all(promises).then(function () {
                                createBufferPdf();
                            })];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        (0, hooks_1.useEffect)(function () {
            if (props.homeUrl) {
                ekitGetObject(start, end);
            }
        }, [props.homeUrl]);
        (0, hooks_1.useEffect)(function () {
            var container = document.getElementById('thumbnail-container');
            container.addEventListener('scroll', function (e) {
                var startCurrent = 0;
                var endCurrent = 3;
                var renderFlagCurrent = false;
                setStart(function (start) {
                    startCurrent = start;
                    return start;
                });
                setEnd(function (end) {
                    endCurrent = end;
                    return end;
                });
                setRenderFlag(function (renderFlag) {
                    renderFlagCurrent = renderFlag;
                    return renderFlag;
                });
                if (endCurrent > props.totalPage) {
                    container.removeEventListener('scroll', function (e) { ekitGetObject(start, end); });
                    return;
                }
                if (!renderFlagCurrent)
                    ekitGetObject(startCurrent, endCurrent);
            });
            return function () { return container.removeEventListener('scroll', function (e) { ekitGetObject(start, end); }); };
        }, []);
        var renderCanvas = function (page, canvas) {
            var _a, _b;
            var scale = 200 / page.getViewport({ scale: 1 }).height;
            var viewport = page.getViewport({ scale: scale });
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            canvas.style.cursor = 'pointer';
            var context = canvas.getContext('2d');
            if (viewport.width > viewport.height) {
                (_a = document.getElementById('thumbnail-container')) === null || _a === void 0 ? void 0 : _a.classList.add('landscape-thumb');
            }
            else {
                (_b = document.getElementById('thumbnail-container')) === null || _b === void 0 ? void 0 : _b.classList.remove('landscape-thumb');
            }
            // Render PDF page into canvas context
            var renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
            });
        };
        var displayThumnail = function (start, end) {
            var _loop_1 = function (i) {
                var div = document.createElement('div');
                div.classList.add('oj-flex-item', 'thumbnail-canvas');
                if (i === 1)
                    div.classList.add('thumb-selected');
                var span = document.createElement('div');
                span.classList.add('oj-xl-margin-4x-bottom', 'oj-flex', 'oj-typography-body-sm', 'oj-typography-bold', 'thumbnail-count');
                span.innerHTML = "".concat(i);
                var canvas = document.createElement('canvas');
                canvas.onclick = function () {
                    var _a, _b;
                    if (document.querySelector('#thumbnail-container') != null) {
                        (_a = document.querySelector('#thumbnail-container')) === null || _a === void 0 ? void 0 : _a.querySelectorAll('div.thumbnail-canvas').forEach(function (val) {
                            val.classList.remove('thumb-selected');
                        });
                        (_b = document.getElementById('thumbnail-container')) === null || _b === void 0 ? void 0 : _b.querySelectorAll('div.thumbnail-canvas')[i - 1].classList.add('thumb-selected');
                    }
                    if (window.innerWidth < 768 && window.innerHeight < 1024) {
                        // It's likely a mobile device
                        props.setThumbnailShow(false);
                        props.setClickMobileNavigate(function (prev) { return !prev; });
                    }
                    props.setNowPage(i);
                };
                if (props.streamPdfDistBytes[i - 1]) {
                    props.streamPdfDistBytes[i - 1].getPage(1).then(function (page) {
                        renderCanvas(page, canvas);
                    });
                }
                div.appendChild(canvas);
                div.appendChild(span);
                var container = document.getElementById('thumbnail-container');
                container.appendChild(div);
            };
            for (var i = start; i <= end; i++) {
                _loop_1(i);
            }
            if (end !== props.totalPage) {
                setStart(end + 1);
                setEnd(function (prev) { return Math.min(prev + 3, props.totalPage); });
                setRenderFlag(false);
            }
        };
        (0, hooks_1.useEffect)(function () {
            if (props.streamPdfDistBytes.length > 0) {
                var tempEnd = end;
                for (var i = 0; i < props.totalPage; i++) {
                    if (props.streamPdfDistBytes[i - 1]) {
                        tempEnd++;
                    }
                    else {
                        break;
                    }
                }
                displayThumnail(start, tempEnd);
            }
        }, []);
        return ((0, preact_1.h)("div", { id: "thumbnail-container", className: "oj-flex oj-sm-flex-direction-row oj-xl-padding-8x ekit-thumbnail-box oj-sm-padding-4x-horizontal oj-sm-padding-4x-top", style: { display: props.thumbnailShow ? '' : 'none' } }));
    };
    exports.default = ThumbnailComponent;
});
