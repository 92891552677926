var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
define(["require", "exports", "preact", "preact/hooks", "../../../../model/index", "../../dtos/story.dto", "../../story-2.action", "../../../shared.mod/index", "../../css/styles.css"], function (require, exports, preact_1, hooks_1, index_1, story_dto_1, story_2_action_1, index_2) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function Carousel(_a) {
        var storyId = _a.storyId, carouselMetadata = _a.carouselMetadata, andWhere = _a.andWhere, keyword = _a.keyword, size = _a.pagination.size, key = _a.key, onContent = _a.onContent, onEmptyContent = _a.onEmptyContent;
        var _b = (0, hooks_1.useState)(story_dto_1.emptyStoryContent), carouselContent = _b[0], setCarouselContent = _b[1];
        var _c = (0, hooks_1.useState)(false), isLoadingCarouselContent = _c[0], setIsLoadingCarouselContent = _c[1];
        var _d = (0, hooks_1.useState)(0), carouselContentOffset = _d[0], setCarouselContentOffset = _d[1];
        var _e = (0, hooks_1.useState)(false), loadedAllCarouselContent = _e[0], setLoadedAllCarouselContent = _e[1];
        var backbone = index_1.Backbone.getInstance();
        var _f = backbone.state.userProfile, userProfile = _f === void 0 ? {} : _f;
        var _g = userProfile.accessToken, accessToken = _g === void 0 ? '' : _g;
        function afterChange(ev) {
            if (!loadedAllCarouselContent) {
                setIsLoadingCarouselContent(true);
            }
        }
        function launchContent(props) {
            console.info('launchContent');
        }
        function getLaunchContentURL() {
            console.info('getLaunchContentURL');
        }
        (0, hooks_1.useEffect)(function () {
            setIsLoadingCarouselContent(true);
        }, []);
        (0, hooks_1.useEffect)(function () {
            if (isLoadingCarouselContent) {
                (0, story_2_action_1.readStoryCarouselContent)(storyId, carouselMetadata.id, andWhere, keyword, { size: size, offset: carouselContentOffset }, accessToken)
                    .then(function (res) {
                    var _a;
                    if (!res.searchResponse.hits.hits.length) {
                        if (onEmptyContent) {
                            onEmptyContent();
                        }
                        setLoadedAllCarouselContent(true);
                        setIsLoadingCarouselContent(false);
                        return;
                    }
                    else if (onContent) {
                        onContent();
                    }
                    if (carouselContentOffset === 0) {
                        // we have to fill the entire carousel with the first card to prevent re-render on loading more content.
                        var fill = res.searchResponse.hits.total.value - res.searchResponse.hits.hits.length;
                        (_a = res.searchResponse.hits.hits).push.apply(_a, new Array(fill).fill(res.searchResponse.hits.hits[0]));
                        setCarouselContent(res);
                    }
                    else {
                        // we replace the the container reference one by one once we have the next results.
                        for (var i = 0; i < res.searchResponse.hits.hits.length; i++) {
                            carouselContent.searchResponse.hits.hits[i + carouselContentOffset] = res.searchResponse.hits.hits[i];
                        }
                    }
                    if (res.searchResponse.hits.hits.length < size) {
                        setLoadedAllCarouselContent(true);
                    }
                    setIsLoadingCarouselContent(false);
                    setCarouselContentOffset(carouselContentOffset + size);
                });
            }
        }, [isLoadingCarouselContent]);
        return ((0, preact_1.h)(preact_1.Fragment, null, carouselContent.searchResponse.hits.hits.length > 0 &&
            (0, preact_1.h)("div", { key: key },
                (0, preact_1.h)(index_2.TileboxCarousel, { title: carouselMetadata.title, subtitle: carouselMetadata.description, hits: __assign({}, carouselContent.searchResponse.hits), clickViewDetails: function (props) { launchContent(props); }, slidesToShow: 2, storyMoreIcon: false, afterChange: afterChange, isCarouselLoading: isLoadingCarouselContent, getViewDetailsURL: getLaunchContentURL }))));
    }
    exports.default = Carousel;
});
