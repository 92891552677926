var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "preact", "lodash", "react", "react-i18next", "../proctor-exam-tilebox/proctor-exam-tilebox.component", "ojs/ojprogress-circle", "ojs/ojprogress-bar", "./styles.css"], function (require, exports, preact_1, _, react_1, react_i18next_1, proctor_exam_tilebox_component_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    _ = __importStar(_);
    var TileContentCollapsed = function (props) {
        if (props === void 0) { props = {}; }
        var t = (0, react_i18next_1.useTranslation)().t;
        var node = props.node, clickViewDetails = props.clickViewDetails, int2Duration = props.int2Duration;
        var progressClass = (node === null || node === void 0 ? void 0 : node.totalProgress) > 0 ? ((node === null || node === void 0 ? void 0 : node.totalProgress) === 100 ? 'lp-page-container-completed' : 'lp-page-container-in-progress') : '';
        progressClass = progressClass || ((node === null || node === void 0 ? void 0 : node.completed) === 'Y' ? 'lp-page-container-completed' : '');
        var containerIdPrefix = 'unproctorExam';
        var consumptionMin = function (minutes) {
            if (minutes > 60) {
                return (minutes / 60) + ' hours';
            }
            else {
                return parseInt(minutes) + ' minutes';
            }
        };
        var _a = (0, react_1.useState)('Get Started'), buttonText = _a[0], setButtonText = _a[1];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var _b = (0, react_1.useState)(''), registrationStatus = _b[0], setRegistrationStatus = _b[1];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var _c = (0, react_1.useState)(''), attemptNotice = _c[0], setAttemptNotice = _c[1];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var _d = (0, react_1.useState)(''), disabledFlag = _d[0], setDisabledFlag = _d[1];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var _e = (0, react_1.useState)(''), passScoreText = _e[0], setPassScoreText = _e[1];
        var processNode = function (node) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            var notice = '';
            if ((node === null || node === void 0 ? void 0 : node.certStatus) && (node === null || node === void 0 ? void 0 : node.certStatus.toLowerCase()) === 'passed') {
                setRegistrationStatus('<span class="exam_pass">Passed!</span>');
                setButtonText('View Details');
            }
            else {
                if (typeof (node === null || node === void 0 ? void 0 : node.certStatus) === 'undefined' || (node === null || node === void 0 ? void 0 : node.certStatus.toLowerCase()) === 'failed') {
                    // if it's user's first time on the site and has less than 30min consumption, we disable the attend button
                    if ((node === null || node === void 0 ? void 0 : node.availableAttempts) === ((_a = node === null || node === void 0 ? void 0 : node.rules) === null || _a === void 0 ? void 0 : _a.rules[0].max_attempts) && (node === null || node === void 0 ? void 0 : node.consumption) < ((_b = node === null || node === void 0 ? void 0 : node.rules) === null || _b === void 0 ? void 0 : _b.rules[0].min_1_consumption)) {
                        notice = 'This is your first attempt; you must <strong>complete ' + consumptionMin((_c = node === null || node === void 0 ? void 0 : node.rules) === null || _c === void 0 ? void 0 : _c.rules[0].min_1_consumption) + '</strong> or more of learning content related to this exam before you attend.';
                        if ((node === null || node === void 0 ? void 0 : node.consumption) < ((_d = node === null || node === void 0 ? void 0 : node.rules) === null || _d === void 0 ? void 0 : _d.rules[0].min_1_consumption) && !(node === null || node === void 0 ? void 0 : node.isGuest)) {
                            setDisabledFlag('disabled');
                        }
                    }
                    else if ((node === null || node === void 0 ? void 0 : node.availableAttempts) === 2) {
                        setRegistrationStatus('<span class="exam_pass">Retry exam</span>');
                        notice = 'This is your second attempt; you must <strong>complete ' + consumptionMin(node === null || node === void 0 ? void 0 : node.rules.rules[0].min_2_consumption) + '</strong> or more of learning content related to this exam before you attend.';
                        if ((node === null || node === void 0 ? void 0 : node.consumption) < ((_e = node === null || node === void 0 ? void 0 : node.rules) === null || _e === void 0 ? void 0 : _e.rules[0].min_2_consumption) && !(node === null || node === void 0 ? void 0 : node.isGuest)) {
                            setDisabledFlag('disabled');
                        }
                    }
                    else if ((node === null || node === void 0 ? void 0 : node.availableAttempts) === 1) {
                        setRegistrationStatus('<span class="exam_pass">Retry exam</span>');
                        notice = 'This is your third attempt; you must <strong>complete ' + consumptionMin((_f = node === null || node === void 0 ? void 0 : node.rules) === null || _f === void 0 ? void 0 : _f.rules[0].min_3_consumption) + '</strong> or more of learning content related to this exam before you attend.';
                        if ((node === null || node === void 0 ? void 0 : node.consumption) < ((_g = node === null || node === void 0 ? void 0 : node.rules) === null || _g === void 0 ? void 0 : _g.rules[0].min_3_consumption) && !(node === null || node === void 0 ? void 0 : node.isGuest)) {
                            setDisabledFlag('disabled');
                        }
                    }
                    else if ((node === null || node === void 0 ? void 0 : node.availableAttempts) <= 0) {
                        setButtonText('Results');
                        setPassScoreText('You have used your 3 free exam attempts for this certification.');
                    }
                }
                else if (((_h = node === null || node === void 0 ? void 0 : node.certStatus) === null || _h === void 0 ? void 0 : _h.toLowerCase()) === 'in progress') {
                    setButtonText('View Details');
                }
            }
            var learningConsumed = "<span style=\"display: block; margin: 10px 0; font-weight: 700;\">Learning completed: ".concat((0, proctor_exam_tilebox_component_1.proctorMinuteToDay)(node.consumption), "</span>");
            setAttemptNotice(notice + learningConsumed);
        };
        (0, react_1.useEffect)(function () {
            if (node) {
                processNode(node);
            }
        }, [node]);
        return ((0, preact_1.h)("div", { className: "oj-sm-flex-wrap-nowrap oj-flex11 oj-sp-expandable-list-item-header oj-sm-padding-4x-start oj-sm-padding-2x-end oj-sm-padding-3x-vertical oj-collapsible-header oj-sp-expandable-list-item-header-sticky" }, 
        /* @ts-expect-error */
        (0, preact_1.h)("oj-sp-list-item-template", { class: "oj-sm-12 oj-sp-expandable-list-item-nav-text oj-complete" }, (0, preact_1.h)("div", { className: "lp-page-component-box oj-sm-justify-content-space-between oj-sm-align-items-center oj-flex11 lp-page-component lp-page-comp-exam-unproc ".concat(progressClass) },
            (0, preact_1.h)("div", { className: "oj-flex oj-sm-align-items-center" },
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sp-list-item-template-primary-col oj-md-order-2 oj-sm-12 oj-lg-8" },
                    (0, preact_1.h)("div", { className: "oj-flex oj-md-flex-wrap-nowrap oj-sp-list-item-template-column-0" },
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-md-flex-1 oj-sp-list-item-template-flex-shrink oj-sp-list-item-template-main-texts-container" },
                            (0, preact_1.h)("oj-list-item-layout", { class: "oj-sp-list-item-template-list-item-layout oj-complete" },
                                (0, preact_1.h)("div", { className: "oj-listitemlayout-grid" },
                                    (0, preact_1.h)("div", { className: "oj-listitemlayout-textslots" },
                                        (0, preact_1.h)("div", null,
                                            (0, preact_1.h)("div", { className: "oj-flex oj-sm-flex-direction-column" },
                                                (0, preact_1.h)("div", { className: "oj-sp-list-item-template-overline-text oj-flex-item" },
                                                    (0, preact_1.h)("span", { className: "oj-typography-body-xs oj-text-color-secondary oj-typography-bold" }, 'Online Certification Exam')),
                                                (0, preact_1.h)("span", { className: "oj-sp-list-item-template-primary-text oj-flex-item oj-typography-body-md oj-text-color-primary oj-sm-padding-1x-bottom" },
                                                    (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Name-").concat(node === null || node === void 0 ? void 0 : node.id), onClick: typeof (node === null || node === void 0 ? void 0 : node.moocCount) === 'undefined' ? function () { clickViewDetails(node); } : undefined, className: "oj-text-color-primary oj-typography-subheading-xs oj-typography-bold", dangerouslySetInnerHTML: { __html: _.unescape(node === null || node === void 0 ? void 0 : node.name) || '' } })))),
                                        (0, preact_1.h)("div", null,
                                            (0, preact_1.h)("div", { className: "oj-flex-item oj-flex oj-sp-list-item-template-badge-top-container oj-sp-list-item-template-md-badge-fixed " },
                                                (0, preact_1.h)("div", { className: "oj-sp-list-item-template-badge-container" }))),
                                        (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Duration-").concat(node === null || node === void 0 ? void 0 : node.id), className: 'lp-page-course-duration oj-text-left' }, (node === null || node === void 0 ? void 0 : node.duration) && (0, preact_1.h)("span", { className: "oj-flex-item oj-typography-body-xs oj-text-color-secondary oj-typography-bold" }, int2Duration((node === null || node === void 0 ? void 0 : node.duration) || 0))),
                                        (0, preact_1.h)("div", { className: 'oj-sm-margin-2x-bottom oj-sm-margin-2x-top oj-typography-body-sm' },
                                            (0, preact_1.h)("span", null, "You must register, attend and pass this exam to receive your official credential for this certification.")),
                                        (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Consumption-").concat(node === null || node === void 0 ? void 0 : node.id), className: 'oj-sm-margin-2x-bottom oj-typography-body-sm oj-typography-bold' },
                                            (0, preact_1.h)("span", null,
                                                "Learning completed: ",
                                                (0, proctor_exam_tilebox_component_1.proctorMinuteToDay)((node === null || node === void 0 ? void 0 : node.consumption) || 0))),
                                        (0, preact_1.h)("div", { className: "oj-flex-item oj-flex oj-sp-list-item-template-badge-top-container oj-sp-list-item-template-md-badge-fixed " },
                                            (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Free-").concat(node === null || node === void 0 ? void 0 : node.id), className: "oj-sp-list-item-template-badge-container" }, ((node === null || node === void 0 ? void 0 : node.freeYn) && (node === null || node === void 0 ? void 0 : node.freeYn) === 'Y') &&
                                                (0, preact_1.h)(preact_1.Fragment, null,
                                                    (0, preact_1.h)("span", { className: "oj-badge oj-badge-sm oj-badge-success oj-badge-subtle" }, t('Free')),
                                                    "\u00A0"))))))))),
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sp-list-item-template-primary-col oj-md-order-3 oj-sm-12 oj-lg-4" },
                    (0, preact_1.h)("div", { className: "oj-flex oj-md-flex-wrap-nowrap oj-sp-list-item-template-column-0" },
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-md-flex-1 oj-sp-list-item-template-flex-shrink oj-sp-list-item-template-main-texts-container" },
                            (0, preact_1.h)("div", { className: "oj-listitemlayout-grid" },
                                (0, preact_1.h)("div", { className: "oj-listitemlayout-textslots oj-sm-justify-content-space-between oj-sm-align-items-center oj-flex" },
                                    (0, preact_1.h)("div", { className: "oj-sm-5 oj-md-6" },
                                        (0, preact_1.h)("span", { id: "".concat(containerIdPrefix, "CompletionStatus-").concat(node === null || node === void 0 ? void 0 : node.id), className: 'oj-lg-padding-4x-start' }, !(node === null || node === void 0 ? void 0 : node.isGuest) && (node === null || node === void 0 ? void 0 : node.completed) === 'Y' && (node === null || node === void 0 ? void 0 : node.certStatus) && (0, preact_1.h)("span", { className: "oj-badge oj-badge-subtle" }, "".concat((node === null || node === void 0 ? void 0 : node.completed) === 'Y' ? 'Passed!' : (node === null || node === void 0 ? void 0 : node.certStatus) || 'Not Completed')))),
                                    (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Button-").concat(node === null || node === void 0 ? void 0 : node.id), className: "oj-sm-7 oj-md-6 oj-text-right" }, node &&
                                        (0, preact_1.h)("oj-button", { class: "oj-button-md", chroming: "callToAction", id: "scheduleExam-".concat(node === null || node === void 0 ? void 0 : node.id), onojAction: function (e) { e.preventDefault(); clickViewDetails(node); } }, buttonText))))))))) /* @ts-expect-error */)));
    };
    function UnProctorExamTileBox(props) {
        if (props === void 0) { props = {}; }
        var node = props.node, offeringId = props.offeringId, isFavorite = props.isFavorite, clickViewDetails = props.clickViewDetails, int2Duration = props.int2Duration;
        return ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)(TileContentCollapsed, { node: node, offeringId: offeringId, isFavorite: isFavorite, clickViewDetails: clickViewDetails, int2Duration: int2Duration })));
    }
    exports.default = UnProctorExamTileBox;
});
