define(["require", "exports", "preact", "preact/compat"], function (require, exports, preact_1, compat_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var SupportSection = (0, compat_1.memo)(function (_a) {
        var courseId = _a.courseId, eventId = _a.eventId, _b = _a.position, position = _b === void 0 ? 2 : _b;
        return ((0, preact_1.h)("oj-collapsible", { id: "c3" },
            (0, preact_1.h)("div", { slot: "header", className: "stepHeader" },
                (0, preact_1.h)("h4", { className: "oj-typography-subheading-xs" }, "".concat(position, ": Support"))),
            (0, preact_1.h)("div", { className: "oj-bg-neutral-20 oj-sm-padding-4x" },
                (0, preact_1.h)("p", { className: "oj-typography-body-xs" },
                    "Facing a Technical Issue with the Lab Environment?",
                    ' ',
                    (0, preact_1.h)("a", { className: "oj-link-standalone", target: "_blank", href: "https://help-education.oracle.com/pls/apex/f?p=109:1:::::p_prod_id,p_org_id,p_lang,P_ATTR1,P_ATTR2:80,1001,US,".concat(eventId, ",").concat(courseId), rel: "noreferrer" }, "Open a support ticket.")))));
    });
    exports.default = SupportSection;
});
