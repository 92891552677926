define(["require", "exports", "preact", "../../css/styles.css", "./refine-section.css"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.RefineHeader = void 0;
    var RefineHeader = function (props) {
        var filterVisible = props.filterVisible, handleClick = props.handleClick, resetFilters = props.resetFilters, _a = props.filterCount, filterCount = _a === void 0 ? 0 : _a, clearSearch = props.clearSearch;
        var filterButState = (window.innerWidth < 1024) && (filterVisible);
        var clearFilterButState = (filterCount === 0) || (filterButState);
        return ((0, preact_1.h)("div", { className: "oj-flex filterPanel" },
            (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-only-width-1/5 oj-lg-width-1/3 oj-xl-width-1/4 oj-sm-only-padding-1x-end" },
                (0, preact_1.h)("oj-button", { disabled: filterButState, id: "oj-sp-card-footer-1_more1", title: "Filter", chroming: "callToAction", style: { marginRight: '1em' }, onojAction: handleClick },
                    (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-filter" }), "Filter (".concat(filterCount, ")")),
                (0, preact_1.h)("oj-button", { disabled: clearFilterButState, class: "oj-button oj-button-outlined-chrome oj-button-text-only ".concat(filterCount > 0 ? 'oj-enabled' : 'oj-disabled', " oj-complete oj-default"), chroming: "outlined", title: "Clear Filters", onojAction: resetFilters },
                    (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-reset-variable" }),
                    "Clear Filters")),
            clearSearch &&
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-flex-initial" },
                    (0, preact_1.h)("oj-button", { title: "Clear Search", chroming: "callToAction", onojAction: clearSearch }, "Clear Search"))));
    };
    exports.RefineHeader = RefineHeader;
    function RefineSection(props) {
        if (props === void 0) { props = {}; }
        return ((0, preact_1.h)("div", { className: "refine-section-container" }));
    }
    exports.default = RefineSection;
});
