var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
define(["require", "exports", "preact", "./treeNode", "preact/hooks", "./styles.css", "ojs/ojtreeview", "ojs/ojinputtext", "ojs/ojtreeview", "ojs/ojselectsingle"], function (require, exports, preact_1, treeNode_1, hooks_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var SideBookmarksComponent = function (props) {
        var _a;
        var _b = (0, hooks_1.useState)(''), searchText = _b[0], setSearchText = _b[1];
        var _c = (0, hooks_1.useState)([]), resultArray = _c[0], setResultArray = _c[1];
        (0, hooks_1.useEffect)(function () {
            if (document.getElementById('ekit-toc-content') != null) {
                var ekitTreeView = document.getElementById('ekit-toc-content');
                ekitTreeView.refresh();
            }
            function includeNestedItem(item) {
                var newItem = __assign({}, item);
                if (newItem.items) {
                    newItem.items = newItem.items.map(function (nestedItem) { return includeNestedItem(nestedItem); }).filter(function (nestedItem) { return nestedItem !== null; });
                    newItem.children = newItem.items.map(function (nestedItem) { return includeNestedItem(nestedItem); }).filter(function (nestedItem) { return nestedItem !== null; });
                }
                return newItem.title.toLowerCase().includes(searchText.toLowerCase()) ? newItem : newItem.items.length > 0 ? newItem.items[0] : null;
            }
            // Create a new array by filtering the original nested array
            var filteredArray = props.bookmarks.map(function (item) { return includeNestedItem(item); }).filter(function (item) { return item !== null; });
            setResultArray(filteredArray);
            if (document.getElementById('ekit-toc-content') != null) {
                var ekitTreeView = document.getElementById('ekit-toc-content');
                ekitTreeView.refresh();
            }
        }, [searchText]);
        return ((0, preact_1.h)("div", { style: { width: '26rem' } },
            (0, preact_1.h)("div", { className: "oj-flex oj-sm-justify-content-center oj-sm-align-items-center\r\n        oj-xl-padding-4x-horizontal oj-xl-padding-4x-top oj-sm-padding-4x-horizontal oj-sm-padding-4x-top" },
                (0, preact_1.h)("div", { className: "oj-flex-item" },
                    (0, preact_1.h)("div", { className: "oj-typography-subheading-xs oj-text-color-primary" }, "Table of Contents"),
                    (0, preact_1.h)("div", { className: "oj-typography-body-xs oj-text-color-secondary oj-helper-overflow-wrap-anywhere oj-xl-width-3/4" }, props.fileName)),
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-flex-0" })),
            (0, preact_1.h)("hr", null),
            (0, preact_1.h)("div", { className: "oj-flex oj-xl-margin-4x-bottom oj-xl-padding-4x-horizontal oj-sm-padding-4x-horizontal oj-sm-padding-4x-top" },
                (0, preact_1.h)("oj-input-text", { "label-hint": "Search", "label-edge": "none", placeholder: "Search", class: "csp-search-text-info", onKeyPress: function (e) {
                        var _a;
                        if (e.charCode === 13) {
                            setSearchText((_a = e.target) === null || _a === void 0 ? void 0 : _a.value);
                        }
                    } })),
            (0, preact_1.h)("div", { className: "oj-flex ekit-toc-container oj-xl-padding-4x-horizontal" },
                (0, preact_1.h)("oj-tree-view", { id: "ekit-toc-content", "aria-label": "Tree View with Static HTML", "selection-mode": "single", class: "ekit-toc-treeview" },
                    (0, preact_1.h)("ul", null, searchText
                        ? resultArray.map(function (bookmark, index) {
                            return (0, preact_1.h)(treeNode_1.TreeNode, { key: index, node: bookmark, twoPageShow: props.twoPageShow, totalPage: props.totalPage, setNowPage: props.setNowPage, setBookmarkShow: props.setBookmarkShow });
                        })
                        : (_a = props.bookmarks) === null || _a === void 0 ? void 0 : _a.map(function (bookmark, index) {
                            return (0, preact_1.h)(treeNode_1.TreeNode, { key: index, node: bookmark, totalPage: props.totalPage, setNowPage: props.setNowPage, setBookmarkShow: props.setBookmarkShow, setClickMobileNavigate: props.setClickMobileNavigate });
                        }))))));
    };
    exports.default = SideBookmarksComponent;
});
