define(["require", "exports", "preact"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.LabCalenderSectionComponent = void 0;
    var LabCalenderSectionComponent = function (_a) {
        var months = _a.months, labFormat = _a.labFormat, updateSelectedDateValue = _a.updateSelectedDateValue, selectDateValue = _a.selectDateValue, dateAssigned = _a.dateAssigned;
        var isNotRestrictedRejected = function (labFormat, dateWeek, dateAssigned) {
            if (typeof labFormat === 'string' && (labFormat === null || labFormat === void 0 ? void 0 : labFormat.toUpperCase()) === 'R' && dateWeek === dateAssigned) {
                return false;
            }
            return true;
        };
        return ((0, preact_1.h)("oj-film-strip", { id: "labslotfilmStrip", orientation: "horizontal", "arrow-placement": "adjacent", "arrow-visibility": "visible", maxItemsPerPage: 1 }, (months || []).map(function (_a, index) {
            var monthAbbName = _a.monthAbbName, monthName = _a.monthName, weeks = _a.weeks, year = _a.year;
            return ((0, preact_1.h)("div", { className: "demo-filmstrip-item", key: "".concat(monthName, "_").concat(year) },
                (0, preact_1.h)("ul", { className: "oj-listview-element oj-component-initnode oj-sm-padding-2x", style: { 'text-align': 'center' } },
                    (0, preact_1.h)("li", { className: "labCalendarHeaderLi oj-sm-padding-2x-vertical oj-sm-padding-5x-horizontal oj-listview-focused-element oj-listview-item-element oj-listview-item" },
                        (0, preact_1.h)("span", { className: "oj-typography-body-xs oj-text-color-primary labCalendarHeader" }, "".concat(monthName, " ").concat(year))),
                    (weeks || []).map(function (_a, i) {
                        var colDate = _a.colDate, date = _a.date, day = _a.day, idtlai = _a.idtlai, status = _a.status, type = _a.type;
                        if (typeof status === 'string' && (status === null || status === void 0 ? void 0 : status.toUpperCase()) === 'AVAILABLE' && isNotRestrictedRejected(labFormat, "".concat(day, "-").concat(monthAbbName, "-").concat(year), dateAssigned)) {
                            var value_1 = labFormat.indexOf('A') !== -1 ? "".concat(date, "idtlai").concat(idtlai) : (date || '');
                            return ((0, preact_1.h)("li", { className: "oj-sm-padding-2x-vertical oj-sm-padding-5x-horizontal oj-sm-padding-2x-horizontal oj-listview-focused-element oj-listview-item-element oj-listview-item", key: date },
                                (0, preact_1.h)("label", { className: 'labCalendarDate' },
                                    (0, preact_1.h)("input", { type: "radio", value: value_1, name: "calWeekOptions", checked: selectDateValue === value_1, "aria-label": value_1, onChange: function (e) { e.preventDefault(); updateSelectedDateValue(value_1); } }),
                                    (0, preact_1.h)("span", { className: "oj-sm-padding-2x-start", "aria-hidden": "true" }, "Week of ".concat(monthAbbName, " ").concat(day)))));
                        }
                        else {
                            return ((0, preact_1.h)(preact_1.Fragment, null,
                                (0, preact_1.h)("li", { className: "oj-sm-padding-2x-vertical oj-sm-padding-5x-horizontal oj-sm-padding-2x-horizontal oj-listview-focused-element oj-listview-item-element oj-listview-item", key: date },
                                    (0, preact_1.h)("label", { key: index, className: 'labCalendarDate' },
                                        (0, preact_1.h)("input", { type: "radio", value: "", disabled: true }),
                                        (0, preact_1.h)("span", { className: "oj-sm-padding-2x-start", "aria-hidden": "true" }, "Week of ".concat(monthAbbName, " ").concat(day),
                                            typeof labFormat === 'string' && (labFormat === null || labFormat === void 0 ? void 0 : labFormat.toUpperCase()) === 'R' &&
                                                (0, preact_1.h)("strong", null, " R"))))));
                        }
                    }))));
        })));
    };
    exports.LabCalenderSectionComponent = LabCalenderSectionComponent;
});
