define(["require", "exports", "preact"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var EmptySearch = function (_a) {
        var emptySearchBGURL = _a.emptySearchBGURL, t = _a.t;
        return ((0, preact_1.h)("div", { className: "empty-search-results", style: {
                backgroundImage: "url(".concat(emptySearchBGURL, ")"),
                backgroundSize: 'cover'
            } },
            (0, preact_1.h)("div", { className: "empty-search-results-container" },
                (0, preact_1.h)("div", { className: "oj-sp-empty-state-primary oj-typography-heading-2xl oj-text-color-primary" },
                    (0, preact_1.h)("span", null, t('Explore available learning!'))),
                (0, preact_1.h)("div", { className: "oj-sp-empty-state-secondary oj-typography-body-xl oj-text-color-secondary oj-sm-padding-3x-top oj-md-padding-5x-top" },
                    (0, preact_1.h)("span", { className: "empty" }, "Input a search term or use the filters to find what you are looking for.")))));
    };
    exports.default = EmptySearch;
});
