define(["require", "exports", "preact", "react-i18next", "../../shared.mod/index", "./../style.css"], function (require, exports, preact_1, react_i18next_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var LiveEventResultsHeader = function (props) {
        var t = (0, react_i18next_1.useTranslation)('search').t;
        var _a = props.totalHits, totalHits = _a === void 0 ? 0 : _a, handleClick = props.handleClick, _b = props.filterCount, filterCount = _b === void 0 ? 0 : _b, resetFilters = props.resetFilters, resLoaded = props.resLoaded;
        return ((0, preact_1.h)(preact_1.Fragment, null, (0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)("div", { className: "oj-flex-bar" },
                (0, preact_1.h)("div", { id: 'buttonFiltersLiveEvents', className: "oj-flex-bar-start" },
                    (0, preact_1.h)("oj-button", { id: "oj-sp-card-footer-1_more1", title: "Filter", style: { marginRight: '1em' }, onojAction: handleClick, display: 'all' },
                        (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-filter" }),
                        (0, preact_1.h)("div", null, "Filters (".concat(filterCount, ")"))),
                    (0, preact_1.h)("oj-button", { disabled: (filterCount === 0), id: "oj-sp-card-footer-1_more1", title: "Filter", style: { marginRight: '1em' }, onojAction: resetFilters, display: 'all' },
                        (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-reset-variable" }),
                        (0, preact_1.h)("div", null, 'Clear Filters'))),
                (0, preact_1.h)("div", { className: 'oj-sm-only-hide oj-flex-bar textFiltersLiveEvents' },
                    (0, preact_1.h)("div", { className: "se-summ-head" },
                        !resLoaded &&
                            (0, preact_1.h)(index_1.Spinner, { size: "large" }),
                        resLoaded &&
                            (0, preact_1.h)(preact_1.Fragment, null,
                                (0, preact_1.h)("h2", { id: 'headerLiveeventsResultTitle', style: { fontSize: '1.4rem' } },
                                    " ",
                                    t('showingTotalResults', { total: totalHits })),
                                (0, preact_1.h)("span", null, t('useFiltersRefine')))))))));
    };
    function LiveEventElementsHeader(props) {
        if (props === void 0) { props = {}; }
        var _a = props.handleClick, handleClick = _a === void 0 ? function () { } : _a, _b = props.owners, owners = _b === void 0 ? [] : _b, changeOwner = props.changeOwner, _c = props.totalHits, totalHits = _c === void 0 ? 0 : _c, _d = props.filterCount, filterCount = _d === void 0 ? 0 : _d, currentOwner = props.currentOwner, resetFilters = props.resetFilters, resLoaded = props.resLoaded;
        return ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)("div", { id: 'live-events-tabs', className: "oj-flex oj-sm-padding-1x-top oj-sm-padding-1x-button oj-lg-padding-9x-start oj-sm-padding-4x-start filterPanel navlistcontainer", style: { backgroundColor: 'white' } }, owners.length > 0 && (0, preact_1.h)("oj-navigation-list", { selection: currentOwner || '', "drill-mode": "none", edge: 'top' },
                (0, preact_1.h)("ul", null, owners.map(function (owner) {
                    return (0, preact_1.h)("li", { onClick: function () { changeOwner(owner.key); }, key: owner.key, id: owner.key },
                        (0, preact_1.h)("a", { className: 'live-events-tabs', href: "#" }, owner.key));
                })))),
            (0, preact_1.h)("div", { className: "oj-flex oj-lg-padding-9x-start oj-sm-padding-4x-start oj-lg-padding-4x oj-sm-padding-4x filterPanel" },
                (0, preact_1.h)("div", { className: "oj-flex-item" },
                    (0, preact_1.h)(LiveEventResultsHeader, { totalHits: totalHits, handleClick: handleClick, filterCount: filterCount, resetFilters: resetFilters, resLoaded: resLoaded })))));
    }
    exports.default = LiveEventElementsHeader;
});
