define(["require", "exports", "preact", "react-i18next", "../../../../model/index"], function (require, exports, preact_1, react_i18next_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var GuestProfileDropDown = function (props) {
        var t = (0, react_i18next_1.useTranslation)('header').t;
        var flushSession = props.flushSession, handleMenuStatus = props.handleMenuStatus;
        var frontend = index_1.Backbone.getInstance().state.learnerRoleProfile.frontend;
        return ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)("slot", { className: "pad-left" },
                (0, preact_1.h)("p", null,
                    (0, preact_1.h)("strong", null, t('MyAccount')))),
            (0, preact_1.h)("slot", { className: "pad-left" }, t('Manage your account and access personalized content.')),
            (0, preact_1.h)("oj-option", { value: { executable: function () { window.open("https://profile.oracle.com/myprofile/account/create-account.jspx?pid=OUDM&nexturl=".concat(encodeURIComponent(window.location.href))); } } },
                (0, preact_1.h)("a", { href: "https://profile.oracle.com/myprofile/account/create-account.jspx?pid=OUDM&nexturl=".concat(encodeURIComponent(window.location.href)), value: "firstMenuItemGuest", style: { marginBottom: '14px' } }, t('Sign up for an Oracle Account'))),
            (0, preact_1.h)("oj-option", { style: { marginBottom: '20px' }, value: { executable: function () { flushSession(); } } },
                (0, preact_1.h)("oj-button", { chroming: "callToAction", onClick: function (e) { flushSession(); }, style: { display: 'inline-block' } }, t('Sign in to my account'))),
            (0, preact_1.h)("hr", null),
            (0, preact_1.h)("oj-option", { value: { executable: function () { window.open(frontend.helpSupportUrl.default); } } },
                (0, preact_1.h)("a", { href: frontend.helpSupportUrl.default, target: '_blank', rel: 'noreferrer', onClick: function (e) { handleMenuStatus(); e.preventDefault(); e.stopPropagation(); window.open(frontend.helpSupportUrl.default, '_blank'); } }, t('HelpSupport'))),
            (0, preact_1.h)("oj-option", { value: { executable: function () { window.open(frontend.termsAndConditionsUrl.default); } } },
                (0, preact_1.h)("a", { href: frontend.termsAndConditionsUrl.default, target: '_blank', rel: 'noreferrer', onClick: function (e) { handleMenuStatus(); e.preventDefault(); e.stopPropagation(); window.open(frontend.termsAndConditionsUrl.default, '_blank'); } }, t('TermsofUse')))));
    };
    exports.default = GuestProfileDropDown;
});
