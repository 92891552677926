define(["require", "exports", "preact"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var GridSkeleton = function () {
        return ((0, preact_1.h)("div", { className: "oj-flex" },
            (0, preact_1.h)("div", { className: "oj-flex-item" },
                (0, preact_1.h)("div", { className: "oj-listview-cell-element oj-listview-skeleton oj-listview-skeleton-line-height oj-animation-skeleton" })),
            (0, preact_1.h)("div", { className: "oj-flex-item" },
                (0, preact_1.h)("div", { className: "oj-listview-cell-element oj-listview-skeleton oj-listview-skeleton-line-height oj-animation-skeleton" })),
            (0, preact_1.h)("div", { className: "oj-flex-item" },
                (0, preact_1.h)("div", { className: "oj-listview-cell-element oj-listview-skeleton oj-listview-skeleton-line-height oj-animation-skeleton" })),
            (0, preact_1.h)("div", { className: "oj-flex-item" },
                (0, preact_1.h)("div", { className: "oj-listview-cell-element oj-listview-skeleton oj-listview-skeleton-line-height oj-animation-skeleton" })),
            (0, preact_1.h)("div", { className: "oj-flex-item" },
                (0, preact_1.h)("div", { className: "oj-listview-cell-element oj-listview-skeleton oj-listview-skeleton-line-height oj-animation-skeleton" })),
            (0, preact_1.h)("div", { className: "oj-flex-item" },
                (0, preact_1.h)("div", { className: "oj-listview-cell-element oj-listview-skeleton oj-listview-skeleton-line-height oj-animation-skeleton" }))));
    };
    exports.default = GridSkeleton;
});
