define(["require", "exports", "preact", "ojs/ojbutton"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var PrivateEventSuccess = function (_a) {
        var eventCode = _a.eventCode, handleClearPrivateEventCode = _a.handleClearPrivateEventCode;
        return ((0, preact_1.h)("div", { className: 'oj-sm-12' },
            (0, preact_1.h)("span", { className: "oj-sm-padding-2x-horizontal oj-sm-only-margin-3x-bottom oj-md-margin-2x-top private-code-success-message" },
                "Private event code ",
                (0, preact_1.h)("strong", null, "".concat(eventCode)),
                " applied successfully"),
            (0, preact_1.h)("oj-button", { id: "clearPrivateEvent", class: "oj-button-sm oj-md-margin-10x-start", "aria-label": "Clear private event code", chroming: "callToAction", onojAction: function () { return handleClearPrivateEventCode(); }, key: "clearPrivateEvent" }, 'Clear')));
    };
    exports.default = PrivateEventSuccess;
});
