var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
define(["require", "exports", "preact", "preact/hooks", "lodash", "ojs/ojselectcombobox", "./style.css"], function (require, exports, preact_1, hooks_1, _) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.TaSelectWithGroups = void 0;
    _ = __importStar(_);
    var TaSelectWithGroups = function (props) {
        var _a = props.groups, groups = _a === void 0 ? [] : _a, onChange = props.onChange, _b = props.placeholder, placeholder = _b === void 0 ? '' : _b, _c = props.defaultValues, defaultValues = _c === void 0 ? [] : _c, labelTransformation = props.labelTransformation, name = props.name, title = props.title, _d = props.required, required = _d === void 0 ? false : _d, others = __rest(props, ["groups", "onChange", "placeholder", "defaultValues", "labelTransformation", "name", "title", "required"]);
        var _e = (0, hooks_1.useState)(defaultValues || []), selectedItems = _e[0], setSelectedItems = _e[1];
        var _f = (0, hooks_1.useState)(''), focusStyle = _f[0], setFocusStyle = _f[1];
        var handleChange = function (event) {
            setSelectedItems(event.detail.value);
            onChange(event.detail.value);
        };
        (0, hooks_1.useEffect)(function () {
            if (props.resetSelectedItems) {
                setSelectedItems(defaultValues || []);
                props.setResetSelectedItems(false);
            }
        }, [props.resetSelectedItems]);
        return ((0, preact_1.h)("oj-select-many", __assign({ required: required, placeholder: placeholder, value: _.uniq(selectedItems), onvalueChanged: handleChange, "label-hint": title, style: { transform: focusStyle }, onFocus: function () { return setFocusStyle('scale(1.03)'); }, onBlur: function () { return setFocusStyle(''); }, "label-edge": "inside", class: "percentage-width", minimumResultsForSearch: window.innerWidth <= 1024 ? 0 : 50, id: 'preferences' + name.replace(/\s/g, '') }, others), groups.map(function (_a, i) {
            var label = _a.label, items = _a.items;
            return items.length > 0
                ? ((0, preact_1.h)("oj-optgroup", { label: label, class: "optGroup" }, items.map(function (itm, i) {
                    var label = itm.label, value = itm.value, labelAsValue = itm.labelAsValue;
                    return ((0, preact_1.h)("oj-option", { title: value, id: label + i, value: value, key: "".concat(label).concat(value).concat(i) }, labelAsValue === true ? label : value));
                })))
                : (0, preact_1.h)("oj-option", { value: label, key: label, disabled: true }, "No ".concat(name, " for ").concat(label));
        })));
    };
    exports.TaSelectWithGroups = TaSelectWithGroups;
    function TaSelect(props) {
        var _a = props.options, options = _a === void 0 ? [] : _a, onChange = props.onChange, _b = props.placeholder, placeholder = _b === void 0 ? '' : _b, _c = props.width, width = _c === void 0 ? '100%' : _c, _d = props.mode, mode = _d === void 0 ? 'multiple' : _d, _e = props.defaultValues, defaultValues = _e === void 0 ? [] : _e, _f = props.required, required = _f === void 0 ? false : _f, others = __rest(props, ["options", "onChange", "placeholder", "width", "mode", "defaultValues", "required"]);
        var _g = (0, hooks_1.useState)(defaultValues), selectedItems = _g[0], setSelectedItems = _g[1];
        var handleChange = function (selectedItems) {
            setSelectedItems(selectedItems);
            onChange(selectedItems);
        };
        var filteredOptions = options.filter(function (o) { return !selectedItems.includes(o); });
        (0, hooks_1.useEffect)(function () {
            if (props.resetSelectedItems) {
                setSelectedItems(defaultValues || []);
                props.setResetSelectedItems(false);
            }
        }, [props.resetSelectedItems]);
        return ((0, preact_1.h)("oj-select-many", __assign({ required: required, mode: mode, placeholder: placeholder, value: selectedItems, onChange: handleChange, style: { width: width }, minimumResultsForSearch: window.innerWidth <= 1024 ? 0 : 50, className: (filteredOptions.length > 0) ? '' : 'hideElementsSelect' }, others), filteredOptions.length > 0 && filteredOptions.map(function (item, i) {
            if (_.isString(item)) {
                var tmp = item;
                item = { label: tmp, value: tmp };
            }
            var label = item.label, value = item.value;
            return ((0, preact_1.h)("oj-option", { key: value + i, value: value }, label));
        })));
    }
    exports.default = TaSelect;
});
