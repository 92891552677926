define(["require", "exports", "preact", "../../../model/index", "./../css/header-syndicatos-section.css"], function (require, exports, preact_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function SyndicatorHeader() {
        var _a, _b;
        var _c = index_1.Backbone.getInstance().state.userProfile, userProfile = _c === void 0 ? {} : _c;
        return ((0, preact_1.h)("header", { id: 'syndicatorHeader', role: "banner", className: "oj-web-applayout-header" },
            (0, preact_1.h)("div", { className: "oj-web-applayout-max-width oj-flex-bar oj-sm-align-items-center" },
                (0, preact_1.h)("div", { className: "oj-flex-bar-middle oj-sm-align-items-baseline" },
                    (0, preact_1.h)("div", { id: "logo_navLink", className: "logoSection nav-display-flex", style: { display: 'flex', alignItems: 'center', alignSelf: 'center' } },
                        (0, preact_1.h)("span", { className: "oj-ux-ico-oracle-o oj-ux-icon-size-6x oj-link-standalone oj-link-subtle-primary oj-sm-padding-1x-horizontal" }),
                        (0, preact_1.h)("span", null,
                            (0, preact_1.h)("span", { className: "oj-typography-body-lg oj-md-only-hide" },
                                (0, preact_1.h)("b", null, ((_b = (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.user) === null || _a === void 0 ? void 0 : _a.syndicatorData) === null || _b === void 0 ? void 0 : _b.firstName) || 'USER'))))),
                (0, preact_1.h)("div", { className: "oj-flex-bar-end" }))));
    }
    exports.default = SyndicatorHeader;
});
