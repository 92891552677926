define(["require", "exports", "../../../../model/index", "./player-consumption.tracking", "./video-player-kaltura"], function (require, exports, index_1, player_consumption_tracking_1, video_player_kaltura_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var MyKalturaPlayer = /** @class */ (function () {
        function MyKalturaPlayer(playerId, player, componentId) {
            var _this = this;
            this.id = playerId;
            this.player = player;
            this.componentId = componentId;
            this.lastPosition = 0;
            this.player.ready().then(function () {
                _this.setUpListeners();
                _this.dispatchEvent('STARTED', {});
            });
        }
        Object.defineProperty(MyKalturaPlayer.prototype, "playerId", {
            get: function () {
                return this.id;
            },
            enumerable: false,
            configurable: true
        });
        MyKalturaPlayer.prototype.setUpListeners = function () {
            var _this = this;
            var kalturaPlayerInst = new video_player_kaltura_1.VideoPlayerKaltura();
            var _a = player_consumption_tracking_1.PlayerConsumptionTracking.getInstance(kalturaPlayerInst), playerListeners = _a.playerListeners, updatePlayerInst = _a.updatePlayerInst;
            updatePlayerInst(kalturaPlayerInst);
            var listeners = playerListeners();
            this.player.addEventListener(this.player.Event.Core.LOADED_DATA, function (event) {
                _this.playerHandling();
            });
            // listener functions declared on consumption_tracking_new
            this.player.addEventListener('STARTED', function (event) {
                listeners.onStarted();
            });
            this.player.addEventListener('CHANGE_VIDEO', function (event) {
                listeners.onChangeVideo();
            });
            this.player.addEventListener(this.player.Event.Core.PLAY, function (event) {
                listeners.onPlay();
            });
            this.player.addEventListener(this.player.Event.Core.PAUSE, function (event) {
                listeners.onPause();
            });
            this.player.addEventListener(this.player.Event.Core.TIME_UPDATE, function (event) {
                _this.lastPosition = _this.player.currentTime;
                listeners.onTimeUpdate();
            });
            this.player.addEventListener(this.player.Event.Core.SEEKED, function (event) {
                listeners.onSeeked();
            });
            this.player.addEventListener(this.player.Event.Core.SEEKING, function (event) {
                listeners.onKalturaSeeking(_this.lastPosition);
            });
            this.player.addEventListener(this.player.Event.Core.PLAYBACK_ENDED, function (event) {
                listeners.onEnded();
            });
        };
        MyKalturaPlayer.prototype.loadMedia = function (entryId) {
            this.player.loadMedia({ entryId: entryId });
        };
        MyKalturaPlayer.prototype.play = function () {
            this.player.play();
        };
        MyKalturaPlayer.prototype.pause = function () {
            this.player.pause();
        };
        MyKalturaPlayer.prototype.currentTime = function (time) {
            if (time === void 0) { time = null; }
            if (time !== null && !isNaN(time)) {
                this.player.currentTime = time;
            }
            return this.player.currentTime;
        };
        MyKalturaPlayer.prototype.destroy = function () {
            this.player.destroy();
        };
        MyKalturaPlayer.prototype.playerHandling = function () {
        };
        ;
        MyKalturaPlayer.prototype.dispatchEvent = function (type, payload) {
            var FakeEvent = window === null || window === void 0 ? void 0 : window.KalturaPlayer.core.FakeEvent;
            var fakeEvent = new FakeEvent(type, payload);
            this.player.dispatchEvent(fakeEvent);
        };
        return MyKalturaPlayer;
    }());
    var unFreezePlaylist = function () {
        var bckbone = index_1.Backbone.getInstance();
        var course = bckbone.state.course;
        course.videoLoading = false;
        bckbone.setState({ course: course });
    };
    var KalturaHandler = /** @class */ (function () {
        function KalturaHandler() {
            var _this = this;
            this.playerInitialization = function (partnerId, uiConfId, entryId, componentId) {
                var playerLoaded = false;
                try {
                    _this.kalturaPlayer = window === null || window === void 0 ? void 0 : window.KalturaPlayer.setup({
                        targetId: 'ktPlayerPlaceHolder',
                        provider: {
                            partnerId: partnerId,
                            uiConfId: uiConfId
                        },
                        playback: {
                            autoplay: true,
                            playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
                        },
                        ui: {
                            showCCButton: true
                        }
                    });
                    playerLoaded = true;
                }
                catch (e) {
                    console.error(e.message);
                }
                if (playerLoaded) {
                    _this.myPlayer = new MyKalturaPlayer(uiConfId, _this.kalturaPlayer, componentId);
                    _this.myPlayer.loadMedia(entryId);
                }
                unFreezePlaylist();
            };
            this.addKalturaPlayer = function (currentComponent) {
                var partnerId = currentComponent.playerAccountId;
                var uiConfId = currentComponent.playerDataPlayer;
                var entryId = currentComponent.videoId;
                // if there are no scripts that match (current player wasn't previously loaded), append it and load player
                if (!document.getElementById('vidScript_' + uiConfId)) {
                    var s = document.createElement('script');
                    s.id = 'vidScript_' + uiConfId;
                    s.src = '//cdnapisec.kaltura.com/p/' +
                        partnerId +
                        '/embedPlaykitJs/uiconf_id/' +
                        uiConfId;
                    document.body.appendChild(s);
                    s.onload = function () {
                        _this.playerInitialization(partnerId, uiConfId, entryId, currentComponent.id);
                    };
                }
                else {
                    // previously loaded player
                    _this.playerInitialization(partnerId, uiConfId, entryId, currentComponent.id);
                    unFreezePlaylist();
                }
            };
            this.destroyKalturaPlayer = function () {
                if (_this.myPlayer) {
                    _this.myPlayer.destroy();
                }
            };
            this.kalturaPlayer = null;
            this.myPlayer = null;
        }
        KalturaHandler.getInstance = function () {
            if (!KalturaHandler._inst) {
                KalturaHandler._inst = new KalturaHandler();
            }
            return KalturaHandler._inst;
        };
        ;
        KalturaHandler.prototype.triggerChangeVideoEvent = function () {
            if (this.myPlayer) {
                this.myPlayer.dispatchEvent('CHANGE_VIDEO', {});
            }
        };
        return KalturaHandler;
    }());
    ;
    exports.default = KalturaHandler;
});
