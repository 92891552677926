define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.LabOptionType = void 0;
    var LabOptionType;
    (function (LabOptionType) {
        LabOptionType[LabOptionType["PREMIUM_LAB"] = 1] = "PREMIUM_LAB";
        LabOptionType[LabOptionType["FREE_TIER_ACCOUNT"] = 2] = "FREE_TIER_ACCOUNT";
        LabOptionType[LabOptionType["RUN_OWN_TENANCY"] = 3] = "RUN_OWN_TENANCY";
    })(LabOptionType || (exports.LabOptionType = LabOptionType = {}));
    ;
});
