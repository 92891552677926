var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
define(["require", "exports", "preact", "../ta-select/ta-select.component"], function (require, exports, preact_1, ta_select_component_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    ta_select_component_1 = __importStar(ta_select_component_1);
    function ActionableSelect(props) {
        var title = props.title, _a = props.groups, groups = _a === void 0 ? [] : _a, _b = props.onAction, onAction = _b === void 0 ? function () { ; } : _b, name = props.name, _c = props.readOnly, readOnly = _c === void 0 ? false : _c, onChange = props.onChange, resetSelectedItems = props.resetSelectedItems, setResetSelectedItems = props.setResetSelectedItems, externalTitle = props.externalTitle, others = __rest(props, ["title", "groups", "onAction", "name", "readOnly", "onChange", "resetSelectedItems", "setResetSelectedItems", "externalTitle"]);
        return ((0, preact_1.h)(preact_1.Fragment, null,
            (externalTitle && externalTitle.length > 0) &&
                (0, preact_1.h)("p", { className: "oj-typography-bold" }, externalTitle),
            (0, preact_1.h)("label", null, groups && groups.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.items.length) > 0; }).length === 0 &&
                (0, preact_1.h)("div", null,
                    (0, preact_1.h)("span", null,
                        "No ",
                        title,
                        " are available"))),
            readOnly &&
                (0, preact_1.h)("div", { className: "darkTier", onClick: function (e) { onAction(e); } }),
            (groups && groups.length > 0)
                ? (0, preact_1.h)(ta_select_component_1.TaSelectWithGroups, __assign({}, props))
                : (0, preact_1.h)(ta_select_component_1.default, __assign({ title: title, onChange: onChange, name: name, bordered: false, resetSelectedItems: resetSelectedItems, setResetSelectedItems: setResetSelectedItems }, others))));
    }
    exports.default = ActionableSelect;
});
