var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "preact", "preact/hooks", "react-i18next", "lodash", "../../constants", "./styles.css", "ojs/ojprogress-circle"], function (require, exports, preact_1, hooks_1, react_i18next_1, _, constants_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    _ = __importStar(_);
    var TileContentCollapsed = function (props) {
        if (props === void 0) { props = {}; }
        var t = (0, react_i18next_1.useTranslation)().t;
        var node = props.node, clickViewDetails = props.clickViewDetails, nodeScore = props.nodeScore, quizCompleted = props.quizCompleted;
        var containerIdPrefix = 'assessmentQuiz';
        var progressClass = (node === null || node === void 0 ? void 0 : node.totalProgress) > 0 ? ((node === null || node === void 0 ? void 0 : node.totalProgress) === 100 ? 'lp-page-container-completed' : 'lp-page-container-in-progress') : '';
        progressClass = progressClass || ((node === null || node === void 0 ? void 0 : node.completed) === 'Y' ? 'lp-page-container-completed' : '');
        var moocBlockMsg = typeof node.moocCount !== 'undefined' ? (0, constants_1.getMoocBlockMsg)(node.moocCount) : '';
        var LoadExtScripts = function (src, id) {
            var script = document.createElement('script');
            script.src = src;
            script.id = id;
            script.crossOrigin = '';
            script.async = true;
            document.head.appendChild(script);
        };
        (0, hooks_1.useEffect)(function () {
            LoadExtScripts('/quiz_plugin/quiz.js', 'extScriptQuiz' + (node === null || node === void 0 ? void 0 : node.id));
        }, []);
        var launchQuiz = function (node) {
            clickViewDetails(node);
        };
        var scoreThresholdComponent = ((0, preact_1.h)(preact_1.Fragment, null, (node === null || node === void 0 ? void 0 : node.scoreThreshold) &&
            typeof node.moocCount === 'undefined' &&
            (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "ThresholdScore-").concat(node === null || node === void 0 ? void 0 : node.id) }, "Score ".concat(node.scoreThreshold, "% or higher to pass."))));
        var moocBlockMessageComponent = ((0, preact_1.h)(preact_1.Fragment, null, moocBlockMsg &&
            (0, preact_1.h)("div", { dangerouslySetInnerHTML: { __html: moocBlockMsg } })));
        var assesmentStatusComponent = ((0, preact_1.h)(preact_1.Fragment, null,
            quizCompleted &&
                (0, preact_1.h)("span", { className: "oj-badge oj-badge-subtle" }, "Complete"),
            nodeScore
                ? (0, preact_1.h)("span", { className: "oj-badge oj-badge-subtle" },
                    "Highest Score ",
                    nodeScore,
                    "%  ")
                : (0, preact_1.h)("span", { className: "oj-badge oj-badge-subtle" }, "Not Started")));
        return ((0, preact_1.h)("div", { className: "oj-sm-flex-wrap-nowrap oj-flex11 oj-sp-expandable-list-item-header oj-sm-padding-4x-start oj-sm-padding-2x-end oj-sm-padding-3x-vertical oj-collapsible-header oj-sp-expandable-list-item-header-sticky" }, /* @ts-expect-error */ (0, preact_1.h)("oj-sp-list-item-template", { class: "oj-sm-12 oj-sp-expandable-list-item-nav-text oj-complete lp-page-container-title" }, (0, preact_1.h)("div", { className: "lp-page-component-box oj-sm-justify-content-space-between oj-sm-align-items-center oj-flex11 lp-page-component lp-page-comp-ass-quiz lp-page-container-title ".concat(progressClass), onClick: typeof node.moocCount === 'undefined' ? function (e) { e.stopPropagation(); launchQuiz(node); } : undefined, onKeyPress: function (e) { e.stopPropagation(); launchQuiz(node); } },
            (0, preact_1.h)("div", { className: "oj-flex oj-sm-justify-content-space-between oj-sm-align-items-center" },
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sp-list-item-template-primary-col oj-md-order-2 oj-sm-12 oj-lg-8" },
                    (0, preact_1.h)("div", { className: "oj-flex oj-md-flex-wrap-nowrap oj-sp-list-item-template-column-0" },
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-md-flex-1 oj-sp-list-item-template-flex-shrink oj-sp-list-item-template-main-texts-container" },
                            (0, preact_1.h)("oj-list-item-layout", { class: "oj-sp-list-item-template-list-item-layout oj-complete" },
                                (0, preact_1.h)("div", { className: "oj-listitemlayout-grid" },
                                    (0, preact_1.h)("div", { className: "oj-listitemlayout-textslots lp-page-component-block" },
                                        (0, preact_1.h)("div", null,
                                            (0, preact_1.h)("div", { className: "oj-flex oj-sm-flex-direction-column" },
                                                (0, preact_1.h)("div", { className: "oj-sp-list-item-template-overline-text oj-flex-item" },
                                                    (0, preact_1.h)("span", { id: "".concat(containerIdPrefix, "Label-").concat(node === null || node === void 0 ? void 0 : node.id), className: "oj-typography-body-xs oj-text-color-secondary oj-typography-bold" }, (node === null || node === void 0 ? void 0 : node.assessmentLabel) || '-')),
                                                (0, preact_1.h)("span", { className: "oj-sp-list-item-template-primary-text oj-flex-item oj-typography-body-md oj-text-color-primary oj-sm-padding-1x-bottom" },
                                                    (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Name-").concat(node === null || node === void 0 ? void 0 : node.id), className: "oj-text-color-primary oj-typography-subheading-xs oj-typography-bold", dangerouslySetInnerHTML: { __html: _.unescape(node === null || node === void 0 ? void 0 : node.name) || '' } })))),
                                        // for determining score threshold both user data and mooc data is required
                                        node.isLpUserDataLoaded && node.isLpMoocDataLoaded
                                            ? scoreThresholdComponent
                                            : (0, preact_1.h)("div", { style: { padding: '0 0 5px 0' } },
                                                (0, preact_1.h)("oj-progress-circle", { size: "sm", value: -1 })),
                                        node.isLpMoocDataLoaded
                                            ? moocBlockMessageComponent
                                            : (0, preact_1.h)("div", null,
                                                (0, preact_1.h)("oj-progress-circle", { size: "sm", value: -1 })),
                                        (0, preact_1.h)("div", { className: "oj-flex-item oj-flex oj-sp-list-item-template-badge-top-container oj-sp-list-item-template-md-badge-fixed " },
                                            (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "-").concat(node === null || node === void 0 ? void 0 : node.id) + 'FreeYN', className: "oj-sp-list-item-template-badge-container" }, ((node === null || node === void 0 ? void 0 : node.freeYn) && (node === null || node === void 0 ? void 0 : node.freeYn) === 'Y') &&
                                                (0, preact_1.h)(preact_1.Fragment, null,
                                                    (0, preact_1.h)("span", { className: "oj-badge oj-badge-sm oj-badge-success oj-badge-subtle" }, t('Free')),
                                                    "\u00A0"))))))))),
                (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Status-").concat(node === null || node === void 0 ? void 0 : node.id), className: "oj-flex oj-sm-flex-direction-column oj-md-order-3" }, node.isLpUserDataLoaded
                    ? assesmentStatusComponent
                    : (0, preact_1.h)("oj-progress-circle", { size: "sm", value: -1 })))) /* @ts-expect-error */)));
    };
    function AssessmentQuizTileBox(props) {
        if (props === void 0) { props = {}; }
        var node = props.node, offeringId = props.offeringId, isFavorite = props.isFavorite, clickViewDetails = props.clickViewDetails, int2Duration = props.int2Duration, updateTrackingContext = props.updateTrackingContext, selectedNode = props.selectedNode;
        var _a = node.score, score = _a === void 0 ? 0 : _a, _b = node.scoreThreshold, scoreThreshold = _b === void 0 ? 80 : _b;
        var _c = (0, hooks_1.useState)(score), nodeScore = _c[0], setNodeScore = _c[1];
        var _d = (0, hooks_1.useState)(false), quizCompleted = _d[0], setQuizCompleted = _d[1];
        var updateQuizComponentProgress = function (score) {
            updateTrackingContext(score, node);
            if (nodeScore < score) {
                setNodeScore(score);
            }
            if (parseInt(nodeScore) >= parseInt(scoreThreshold) || parseInt(score) >= parseInt(scoreThreshold)) {
                setQuizCompleted(true);
            }
            else {
                setQuizCompleted(false);
            }
        };
        (0, hooks_1.useEffect)(function () {
            setNodeScore(parseInt(score));
            if (parseInt(nodeScore) >= parseInt(scoreThreshold) || parseInt(score) >= parseInt(scoreThreshold)) {
                setQuizCompleted(true);
            }
            else {
                setQuizCompleted(false);
            }
        }, [node, score]);
        if (!window.updateQuizComponentProgress) {
            window.updateQuizComponentProgress = [];
        }
        window.updateQuizComponentProgress[node.id || 0] = updateQuizComponentProgress;
        window.lpQuizProgress = true;
        return ((0, preact_1.h)(TileContentCollapsed, { node: node, offeringId: offeringId, isFavorite: isFavorite, clickViewDetails: clickViewDetails, int2Duration: int2Duration, nodeScore: nodeScore, selectedNode: selectedNode, quizCompleted: quizCompleted }));
    }
    exports.default = AssessmentQuizTileBox;
});
