define(["require", "exports", "preact", "preact/compat"], function (require, exports, preact_1, compat_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var LabAccessDetails = (0, compat_1.memo)(function (_a) {
        var labAssignmentIncomplete = _a.labAssignmentIncomplete, scheduledDateIncomplete = _a.scheduledDateIncomplete, labAssignmentPComplete = _a.labAssignmentPComplete, assignedDate = _a.assignedDate, labAssignmentRejected = _a.labAssignmentRejected, labAssignmentNotAssigned = _a.labAssignmentNotAssigned, labAssignmentIncompleteRestricted = _a.labAssignmentIncompleteRestricted, labAssignmentPCompleteRestricted = _a.labAssignmentPCompleteRestricted, labAssignmentRejectedRestricted = _a.labAssignmentRejectedRestricted, labAssignmentApprovedRestricted = _a.labAssignmentApprovedRestricted;
        return ((0, preact_1.h)(preact_1.Fragment, null,
            labAssignmentIncomplete && ((0, preact_1.h)("div", { className: "oj-flex-item oj-typography-body-sm oj-typography-bold", id: "labAssignmentIncompleteStep3" },
                "Your lab is scheduled on ",
                assignedDate,
                ".",
                !scheduledDateIncomplete && ((0, preact_1.h)("span", { id: "scheduledDateIncomplete1Step3", className: "oj-typography-body-sm oj-typography-bold" }, "Please check back 12 hours before the lab starts to get your username/password.")),
                scheduledDateIncomplete && ((0, preact_1.h)("span", { id: "scheduledDateIncomplete2Step3" },
                    ' ',
                    "has been approved. To get your Username/Password to access the Lab Environment, check back at 9:00am local time on the day your lab is scheduled.",
                    ' ')))),
            labAssignmentPComplete && ((0, preact_1.h)("div", { className: "oj-flex-item oj-typography-body-sm oj-typography-bold", id: "labAssignmentPCompleteStep3" },
                "Your lab is scheduled on ",
                assignedDate,
                ". Please check back 12 hours before the lab starts to get your username/password.")),
            labAssignmentRejected && ((0, preact_1.h)("div", { className: "oj-flex-item oj-typography-body-sm oj-typography-bold", id: "labAssignmentRejectedStep3" },
                "Your schedule request for ",
                assignedDate,
                " has been rejected, please contact Oracle Support for further steps.")),
            labAssignmentNotAssigned && ((0, preact_1.h)("div", { className: "oj-flex-item oj-typography-body-sm oj-typography-bold", id: "labAssignmentNotAssignedStep3" }, "You have not selected a date for your laboratory.")),
            labAssignmentIncompleteRestricted && ((0, preact_1.h)("div", { className: "oj-flex-item oj-typography-body-sm oj-typography-bold", id: "labAssignmentIncompleteStep3Restricted" },
                "Your lab requested for ",
                assignedDate,
                " is being processed.")),
            labAssignmentPCompleteRestricted && ((0, preact_1.h)("div", { className: "oj-flex-item oj-typography-body-sm oj-typography-bold", id: "labAssignmentPCompleteStep3Restricted" }, "Your lab requested for ".concat(assignedDate, " it's being processed. Please check back 12 ours before the lab starts to get your username/password."))),
            labAssignmentRejectedRestricted && ((0, preact_1.h)("div", { className: "oj-flex-item oj-typography-body-sm oj-typography-bold", id: "labAssignmentRejectedStep3Restricted" },
                "Your lab requested for ",
                assignedDate,
                " has been rejected, please choose another week.")),
            labAssignmentApprovedRestricted && ((0, preact_1.h)("div", { className: "oj-flex-item oj-typography-body-sm oj-typography-bold", id: "labAssignmentApprovedStep3Restricted" },
                "Your lab requested for ",
                assignedDate,
                " has been approved. Please check back 12 hours before the lab starts to get your username/password."))));
    });
    exports.default = LabAccessDetails;
});
