define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.arraysEqual = exports.arraysIntersect = void 0;
    function arraysIntersect(array1, array2) {
        if (!(array1 === null || array1 === void 0 ? void 0 : array1.length) || !(array2 === null || array2 === void 0 ? void 0 : array2.length)) {
            return false;
        }
        var set;
        var array;
        if (array1.length > array2.length) {
            set = new Set(array1);
            array = array2;
        }
        else {
            set = new Set(array2);
            array = array1;
        }
        for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
            var element = array_1[_i];
            if (set.has(element)) {
                return true;
            }
        }
        return false;
    }
    exports.arraysIntersect = arraysIntersect;
    function arraysEqual(array1, array2, compareFn) {
        return array1.length === array2.length && JSON.stringify(array1.sort(compareFn)) === JSON.stringify(array2.sort(compareFn));
    }
    exports.arraysEqual = arraysEqual;
});
