define(["require", "exports", "preact", "preact/hooks", "lodash"], function (require, exports, preact_1, hooks_1, lodash_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var FilterPlaylist = function (props) {
        if (props === void 0) { props = {}; }
        var filterPlayList = props.filterPlayList;
        var SEARCH_PLACEHOLDER = 'Search playlist';
        var _a = (0, hooks_1.useState)(''), inputValue = _a[0], setInputValue = _a[1];
        var _b = (0, hooks_1.useState)(SEARCH_PLACEHOLDER), searchPlaceholder = _b[0], setSearchPlaceholder = _b[1];
        var applyFilter = (0, lodash_1.debounce)(function (query) {
            if (!query) {
                filterPlayList('');
                setSearchPlaceholder(SEARCH_PLACEHOLDER);
                return;
            }
            filterPlayList(query);
        }, 500);
        var inputKey = function (event) {
            setInputValue(event.detail.value.replaceAll(/[,?,/"%]+/g, ''));
            applyFilter(event.detail.value.replaceAll(/[,?,/"%]+/g, ''));
        };
        return ((0, preact_1.h)("div", { className: "oj-flex oj-lg-padding-5x-start oj-lg-padding-5x-end oj-flex oj-md-padding-12x-end oj-sm-padding-4x-end" },
            (0, preact_1.h)("oj-input-text", { class: "oj-form-control-max-width-lg", "aria-label": "Search playlist", "clear-icon": "conditional", value: inputValue, labelEdge: 'none', placeholder: searchPlaceholder, onrawValueChanged: inputKey, id: "course-playlist-search", autocomplete: "off" },
                (0, preact_1.h)("div", { slot: "start", className: "oj-text-field-start-end-icon oj-ux-ico-filter", role: "img", "aria-label": "Search Playlist" }))));
    };
    exports.default = FilterPlaylist;
});
