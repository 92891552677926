define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.OrderAccessTypeEnum = exports.OutboxMessageType = exports.ConsumptionContainerType = exports.RequestCallStatus = exports.SourcePage = exports.CarouselStatus = exports.ExamPrep = exports.Certification = exports.Course = exports.LearningPath = exports.LiveSession = exports.CardType = exports.TagColors = exports.BadgeType = exports.MsgStatus = exports.ContentType = void 0;
    exports.default = {};
    var ContentType;
    (function (ContentType) {
        ContentType["None"] = "";
        ContentType["Course"] = "course";
        ContentType["LiveSession"] = "live-session";
        ContentType["LearningPath"] = "learning-path";
        ContentType["Exam"] = "exam";
        ContentType["Module"] = "module";
        ContentType["Component"] = "component";
        ContentType["LiveEventSchedule"] = "live-event-schedule";
        ContentType["LiveEventCourse"] = "live-event-course";
        ContentType["ExamRegistration"] = "exam-registration";
        ContentType["ExamUnproctored"] = "exam-unproctored";
        ContentType["ArticleCourse"] = "article-course";
        ContentType["Quiz"] = "quiz";
        ContentType["Assessment"] = "assessment";
        ContentType["OldCourse"] = "oldcour";
        ContentType["OldComponent"] = "oldcomp";
        ContentType["Lab"] = "lab";
    })(ContentType || (exports.ContentType = ContentType = {}));
    var MsgStatus;
    (function (MsgStatus) {
        MsgStatus["Success"] = "success";
        MsgStatus["Warning"] = "warning";
        MsgStatus["Info"] = "info";
        MsgStatus["Error"] = "error";
    })(MsgStatus || (exports.MsgStatus = MsgStatus = {}));
    var BadgeType;
    (function (BadgeType) {
        BadgeType["Certification"] = "certification";
        BadgeType["Achievement"] = "achievement";
    })(BadgeType || (exports.BadgeType = BadgeType = {}));
    exports.TagColors = {
        Free: { tag: '#CFEBB3', font: '#00688C' },
        Certification: { tag: '#EADDF4', font: '#846A92' }
    };
    var CardType;
    (function (CardType) {
        CardType[CardType["ObjectCard"] = 1] = "ObjectCard";
        CardType[CardType["LargeImageCard"] = 2] = "LargeImageCard";
        CardType[CardType["ElsEventCard"] = 3] = "ElsEventCard";
    })(CardType || (exports.CardType = CardType = {}));
    ;
    var LiveSession;
    (function (LiveSession) {
        LiveSession["title"] = "Live Sessions";
        LiveSession["description"] = "Listen to our experts on trending topics, best practices, how-to tutorials, and exam preparation tips, and stay up to-date on the new features with each product release";
        LiveSession["ctaText"] = "Try Live Sessions";
        LiveSession["icon"] = "oj-ux-ico-instructor-training";
        LiveSession["thumbnail"] = "Abstracts Blue 3 2";
    })(LiveSession || (exports.LiveSession = LiveSession = {}));
    ;
    var LearningPath;
    (function (LearningPath) {
        LearningPath["title"] = "Learning Paths";
        LearningPath["description"] = "Curated collection of topics that will guide you to learn desired skills";
        LearningPath["ctaText"] = "Learning Paths";
        LearningPath["icon"] = "oj-ux-ico-learning-path";
        LearningPath["thumbnail"] = "Abstracts Blue 6 1";
    })(LearningPath || (exports.LearningPath = LearningPath = {}));
    ;
    var Course;
    (function (Course) {
        Course["title"] = "Courses";
        Course["description"] = "Designed and developed to go into depth on a topic, while also adopting a micro-learning format";
        Course["ctaText"] = "Try Courses";
        Course["icon"] = "oj-ux-ico-training-on-demand";
        Course["thumbnail"] = "Abstracts Blue 3 2";
    })(Course || (exports.Course = Course = {}));
    ;
    var Certification;
    (function (Certification) {
        Certification["title"] = "Certifications";
        Certification["description"] = "Add a globally recognized credential to your resume to validate your knowledge and skills. Develop a competitive advantage to achieve professional success";
        Certification["ctaText"] = "Explore Certifications";
        Certification["icon"] = "oj-ux-ico-certification";
        Certification["thumbnail"] = "Abstracts Blue 6 1";
    })(Certification || (exports.Certification = Certification = {}));
    ;
    var ExamPrep;
    (function (ExamPrep) {
        ExamPrep["title"] = "Certification Exam Prep";
        ExamPrep["description"] = "Oracle certifications are industry-recognized credentials that take you career to the next level.";
        ExamPrep["ctaText"] = "Explore Certifications";
        ExamPrep["icon"] = "oj-ux-ico-certificate-path";
        ExamPrep["thumbnail"] = "Abstracts Blue 3 2";
    })(ExamPrep || (exports.ExamPrep = ExamPrep = {}));
    ;
    var CarouselStatus;
    (function (CarouselStatus) {
        CarouselStatus[CarouselStatus["loading"] = 1] = "loading";
        CarouselStatus[CarouselStatus["loaded"] = 2] = "loaded";
        CarouselStatus[CarouselStatus["completelyLoaded"] = 3] = "completelyLoaded";
    })(CarouselStatus || (exports.CarouselStatus = CarouselStatus = {}));
    ;
    var SourcePage;
    (function (SourcePage) {
        SourcePage["HomePage"] = "homePage";
        SourcePage["SearchPage"] = "searchPage";
        SourcePage["StoryPage"] = "storyPage";
        SourcePage["LearningPathPage"] = "learningPathPage";
        SourcePage["ContainerPage"] = "containerPage";
        SourcePage["ComponentPage"] = "componentPage";
        SourcePage["LoginPage"] = "loginPage";
    })(SourcePage || (exports.SourcePage = SourcePage = {}));
    ;
    var RequestCallStatus;
    (function (RequestCallStatus) {
        RequestCallStatus["Pending"] = "pending";
        RequestCallStatus["Success"] = "success";
        RequestCallStatus["Failed"] = "failed";
    })(RequestCallStatus || (exports.RequestCallStatus = RequestCallStatus = {}));
    var ConsumptionContainerType;
    (function (ConsumptionContainerType) {
        ConsumptionContainerType[ConsumptionContainerType["OFERRING"] = 20] = "OFERRING";
        ConsumptionContainerType[ConsumptionContainerType["LEARNING_PATH"] = 21] = "LEARNING_PATH";
        ConsumptionContainerType[ConsumptionContainerType["COURSE"] = 22] = "COURSE";
        ConsumptionContainerType[ConsumptionContainerType["MODULE"] = 23] = "MODULE";
        ConsumptionContainerType[ConsumptionContainerType["OFERRING_TOD"] = 28] = "OFERRING_TOD";
        ConsumptionContainerType[ConsumptionContainerType["NETSUIT"] = 29] = "NETSUIT";
        ConsumptionContainerType[ConsumptionContainerType["LP_MOOC"] = 30] = "LP_MOOC";
        ConsumptionContainerType[ConsumptionContainerType["LIVE_SESSION_COURSE"] = 31] = "LIVE_SESSION_COURSE";
        ConsumptionContainerType[ConsumptionContainerType["LVC_COURSE"] = 32] = "LVC_COURSE";
        ConsumptionContainerType[ConsumptionContainerType["OSPA_JOURNEY"] = 33] = "OSPA_JOURNEY";
        ConsumptionContainerType[ConsumptionContainerType["OSPA_JOURNEY_ITEM"] = 34] = "OSPA_JOURNEY_ITEM";
        ConsumptionContainerType[ConsumptionContainerType["OSPA_COURSE"] = 35] = "OSPA_COURSE";
        ConsumptionContainerType[ConsumptionContainerType["EXAM"] = 36] = "EXAM";
        ConsumptionContainerType[ConsumptionContainerType["DOC_COURSE"] = 37] = "DOC_COURSE";
        ConsumptionContainerType[ConsumptionContainerType["DOC_MODULE"] = 38] = "DOC_MODULE";
    })(ConsumptionContainerType || (exports.ConsumptionContainerType = ConsumptionContainerType = {}));
    var OutboxMessageType;
    (function (OutboxMessageType) {
        OutboxMessageType["CONSUMPTION_UPDATE"] = "consumption_update";
        OutboxMessageType["JOB_FINISHED"] = "job_finished";
        OutboxMessageType["PENDING_RESERVATIONS"] = "pending_reservations";
    })(OutboxMessageType || (exports.OutboxMessageType = OutboxMessageType = {}));
    var OrderAccessTypeEnum;
    (function (OrderAccessTypeEnum) {
        OrderAccessTypeEnum["ULS"] = "ULS";
        OrderAccessTypeEnum["SCLS"] = "SCLS";
        OrderAccessTypeEnum["CLS"] = "CLS";
        OrderAccessTypeEnum["ELS"] = "ELS";
        OrderAccessTypeEnum["TOD"] = "TOD";
        OrderAccessTypeEnum["TRIAL"] = "TRIAL";
    })(OrderAccessTypeEnum || (exports.OrderAccessTypeEnum = OrderAccessTypeEnum = {}));
});
