define(["require", "exports", "preact"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var PrivateEventError = function (_a) {
        var privateEventErrMsg = _a.privateEventErrMsg;
        return ((0, preact_1.h)("div", { className: "oj-sm-12 oj-flex-item private-code-message oj-message-error" },
            (0, preact_1.h)("span", { className: "oj-component-icon oj-message-status-icon oj-message-error-icon", title: "Error", role: "img" }),
            (0, preact_1.h)("span", { className: "oj-message-content" },
                (0, preact_1.h)("div", { className: "private-code-message-detail oj-sm-padding-2x-start" },
                    (0, preact_1.h)("span", null, privateEventErrMsg ||
                        'The code you entered is not valid. Please try again.')))));
    };
    exports.default = PrivateEventError;
});
