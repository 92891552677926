define(["require", "exports", "preact", "ojs/ojpopup", "ojs/ojbutton"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.closeChatVideoModal = exports.openChatVideoModal = void 0;
    function openChatVideoModal() {
        var popup = document.getElementById('ChatBotVideoModal');
        popup.open('#ChatBotVideoDivRef');
    }
    exports.openChatVideoModal = openChatVideoModal;
    function closeChatVideoModal() {
        var popup = document.getElementById('ChatBotVideoModal');
        popup.close();
    }
    exports.closeChatVideoModal = closeChatVideoModal;
    var ChatVideoModal = function (_a) {
        var videoSrc = _a.videoSrc, onModalClose = _a.onModalClose;
        return ((0, preact_1.h)("div", null,
            (0, preact_1.h)("div", { id: "ChatBotVideoDivRef" }),
            (0, preact_1.h)("oj-popup", { id: "ChatBotVideoModal", "auto-dismiss": 'none', modality: 'modal', position: {
                    my: { horizontal: 'center', vertical: 'center' },
                    at: { horizontal: 'center', vertical: 'center' },
                    of: 'window'
                }, tail: 'none', onojClose: function () { return onModalClose(); } },
                (0, preact_1.h)("div", null,
                    (0, preact_1.h)("div", { className: "oj-flex oj-sm-justify-content-flex-end" },
                        (0, preact_1.h)("oj-button", { id: 'ChatBotVideoCloseButton', class: 'oj-button-sm', display: 'icons', onojAction: function () { return closeChatVideoModal(); } },
                            (0, preact_1.h)("span", { slot: 'startIcon', className: 'oj-ux-ico-close' }),
                            "Close")),
                    (0, preact_1.h)("div", { className: "oj-sm-padding-2x" },
                        (0, preact_1.h)("iframe", { src: videoSrc, title: 'video-chat', allow: 'encrypted-media', width: '720px', height: '360px', style: { border: 'none', maxWidth: '100%', maxHeight: '100%' } }))))));
    };
    exports.default = ChatVideoModal;
});
