var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
define(["require", "exports", "preact", "preact/hooks", "./styles.css", "ojs/ojtreeview", "ojs/ojbutton"], function (require, exports, preact_1, hooks_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    // import * as pdfjsLib from 'pdfjs-dist/build/pdf';
    // import { debounce } from 'lodash';
    var isScrollingProgrammatic = false;
    var renderedCanvasIndex = [];
    var ContinousScroll = function (props) {
        var _a = (0, hooks_1.useState)(false), renderFlag = _a[0], setRenderFlag = _a[1];
        var containerRef = (0, hooks_1.useRef)(null);
        (0, hooks_1.useEffect)(function () {
            // pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/build/pdf.worker.js';
        }, []);
        var ekitGetObject = function (startIn, endIn, isScrolling) { return __awaiter(void 0, void 0, void 0, function () {
            function createBufferPdf(startIn, endIn, isScrolling) {
                return __awaiter(this, void 0, void 0, function () {
                    var mergedPdf, i, options;
                    return __generator(this, function (_a) {
                        mergedPdf = props.streamPdfDistBytes || [];
                        for (i = startIn - 1; i < endIn; i++) {
                            if (props.pdfBufferArray[i]) {
                                options = {
                                    data: props.pdfBufferArray[i],
                                    verbosity: 0
                                };
                                /*await pdfjsLib.getDocument(options)
                                  ?.promise.then(function (pdfDoc: any) {
                                    mergedPdf[i] = pdfDoc;
                                  });*/
                            }
                        }
                        props.setStreamPdfDistBytes(__spreadArray([], mergedPdf, true));
                        displayThumnail(startIn, endIn, isScrolling);
                        return [2 /*return*/];
                    });
                });
            }
            function downloadChunks(i) {
                return __awaiter(this, void 0, void 0, function () {
                    var bufferArray, url, responseTwo, buffer;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                bufferArray = props.pdfBufferArray;
                                url = "".concat(props.homeUrl, "/").concat(props.ekitId, "/").concat(i, ".pdf");
                                return [4 /*yield*/, fetch(url, { method: 'GET' })];
                            case 1:
                                responseTwo = _a.sent();
                                return [4 /*yield*/, responseTwo.arrayBuffer()];
                            case 2:
                                buffer = _a.sent();
                                bufferArray[i - 1] = buffer;
                                props.setPdfBufferArray(bufferArray);
                                bufferArray = undefined; // unset
                                return [4 /*yield*/, new Promise(function (resolve, reject) {
                                        resolve(props.pdfBufferArray);
                                    })];
                            case 3:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            }
            var promises, i, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setRenderFlag(true);
                        promises = [];
                        for (i = startIn; i <= endIn; i++) {
                            if (props.streamPdfDistBytes[i]) {
                                if (i === endIn) {
                                    displayThumnail(startIn, endIn, isScrolling);
                                    return [2 /*return*/];
                                }
                                continue;
                            }
                            else {
                                promises.push(downloadChunks(i));
                            }
                        }
                        return [4 /*yield*/, Promise.all(promises).then(function () {
                                createBufferPdf(startIn, endIn, isScrolling);
                            })];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, data];
                }
            });
        }); };
        var handleScroll = function () {
            if (!isScrollingProgrammatic) {
                var container = document.getElementById('continousScroll-container');
                var VisibleCanvas = findMostVisibleCanvas();
                var nowPage_1 = 0;
                props.setNowPage(function (start) {
                    nowPage_1 = start;
                    return start;
                });
                var startCurrent_1 = Number(VisibleCanvas === null || VisibleCanvas === void 0 ? void 0 : VisibleCanvas.id.split('-')[3]) || nowPage_1;
                if (startCurrent_1)
                    props.setNowPage(startCurrent_1);
                var renderFlagCurrent_1 = false;
                setRenderFlag(function (renderFlag) {
                    renderFlagCurrent_1 = renderFlag;
                    return renderFlag;
                });
                if (startCurrent_1 + 3 > props.totalPage || startCurrent_1 > props.totalPage - 3) {
                    container.removeEventListener('scroll', function (e) { ekitGetObject(startCurrent_1, startCurrent_1 + 3, true); });
                    return;
                }
                if (!renderFlagCurrent_1)
                    ekitGetObject(startCurrent_1, startCurrent_1 + 3, true);
            }
        };
        function findMostVisibleCanvas() {
            var container = document.getElementById('continousScroll-container');
            var canvases = container.getElementsByTagName('canvas');
            var maxVisibleArea = 0;
            var mostVisibleCanvas = null;
            var scrollTop = container.scrollTop;
            for (var i = 0; i < canvases.length; i++) {
                var canvas = canvases[i];
                var rect = canvas.getBoundingClientRect();
                // Calculate the visible area of the canvas
                var visibleHeight = Math.min(rect.bottom, container.clientHeight + scrollTop) - Math.max(rect.top, scrollTop);
                var visibleArea = visibleHeight * canvas.offsetHeight;
                // Update the canvas with the most visible area
                if (visibleArea > maxVisibleArea) {
                    maxVisibleArea = visibleArea;
                    mostVisibleCanvas = canvas;
                }
            }
            return mostVisibleCanvas;
        }
        (0, hooks_1.useEffect)(function () {
            var container = document.getElementById('continousScroll-container');
            container.addEventListener('scroll', function (e) {
                handleScroll();
            });
            return function () { return container.removeEventListener('scroll', function (e) {
                ekitGetObject(props.nowPage, props.nowPage + 3, true);
            }); };
        }, [props.continousScroll]);
        var renderCanvas = function (page, canvas) {
            var container = document.getElementById('continousScroll-container');
            var viewport = page.getViewport({ scale: 2 });
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            var context = canvas.getContext('2d');
            container.style.overflow = 'scroll';
            if (viewport.width > viewport.height) {
                container.style.width = 'auto';
                // container.style.height = 'calc(100vh - 244px)';
                container.style.height = '100%';
                container.style.overflow = 'scroll';
            }
            else {
                container.style.width = '490px';
                container.style.height = '100%';
            }
            // Render PDF page into canvas context
            var renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
                props.pageRenderingStatus = false;
            });
        };
        var findNearestIndex = function (currentId) {
            var nearestIndex = null;
            var minDifference = Infinity;
            for (var i = 1; i <= renderedCanvasIndex.length; i++) {
                if (renderedCanvasIndex[i - 1]) {
                    var difference = Math.abs(i - currentId);
                    if (difference > minDifference) {
                        continue;
                    }
                    else {
                        minDifference = difference;
                        nearestIndex = i - 1;
                    }
                }
            }
            return nearestIndex;
        };
        var displayThumnail = function (start, end, isScrolling) {
            var container = document.getElementById('continousScroll-container');
            var _loop_1 = function (i) {
                var canvasIsExist = document.getElementById("continous-scroll-canvas-".concat(i));
                if (props.streamPdfDistBytes[i - 1] && !canvasIsExist) {
                    var canvas_1 = document.createElement('canvas');
                    canvas_1.id = "continous-scroll-canvas-".concat(i);
                    canvas_1.classList.add('continous-canvas');
                    props.streamPdfDistBytes[i - 1].getPage(1).then(function (page) {
                        renderCanvas(page, canvas_1);
                    });
                    var nearestIndex = findNearestIndex(i);
                    var nearestIndexCanvas = document.getElementById("continous-scroll-canvas-".concat(nearestIndex));
                    if ((nearestIndex && nearestIndexCanvas !== null)) {
                        renderedCanvasIndex[i] = true;
                        if (nearestIndex > i) {
                            container.insertBefore(canvas_1, nearestIndexCanvas);
                        }
                        else {
                            container.insertBefore(canvas_1, nearestIndexCanvas.nextSibling);
                        }
                    }
                    else if (i === 1) {
                        renderedCanvasIndex[i] = true;
                        container.appendChild(canvas_1);
                    }
                }
                else {
                    setRenderFlag(false);
                    isScrollingProgrammatic = false;
                }
            };
            for (var i = start; i <= end; i++) {
                _loop_1(i);
            }
            if (end <= props.totalPage) {
                setRenderFlag(false);
            }
        };
        (0, hooks_1.useEffect)(function () {
            var divElement = document.getElementById('canvas-container');
            var container = document.getElementById('continousScroll-container');
            container.style.width = "".concat(divElement.clientWidth, "px");
            divElement.classList.remove('alignCenter');
            divElement.classList.add('alignTop');
        }, []);
        (0, hooks_1.useEffect)(function () {
            ekitGetObject(1, 3, false);
        }, []);
        (0, hooks_1.useEffect)(function () {
            if (!isScrollingProgrammatic) {
                var canvas_2 = document.getElementById("continous-scroll-canvas-".concat(props.nowPage));
                var container_1 = document.getElementById('continousScroll-container');
                if (canvas_2) {
                    isScrollingProgrammatic = true;
                    var topPos = canvas_2 === null || canvas_2 === void 0 ? void 0 : canvas_2.offsetTop;
                    container_1.scrollTop = topPos;
                    // eslint-disable-next-line no-loop-func
                    setTimeout(function () {
                        isScrollingProgrammatic = false;
                    }, 100);
                }
                var scrollToElement = function () { return __awaiter(void 0, void 0, void 0, function () {
                    var topPos;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, ekitGetObject(props.nowPage, props.nowPage + 3, false)];
                            case 1:
                                _a.sent();
                                if (canvas_2) {
                                    isScrollingProgrammatic = true;
                                    topPos = canvas_2 === null || canvas_2 === void 0 ? void 0 : canvas_2.offsetTop;
                                    container_1.scrollTop = topPos;
                                    // eslint-disable-next-line no-loop-func
                                    setTimeout(function () {
                                        isScrollingProgrammatic = false;
                                    }, 100);
                                }
                                return [2 /*return*/];
                        }
                    });
                }); };
                scrollToElement();
            }
        }, [props.nowPage]);
        return ((0, preact_1.h)("div", { ref: containerRef, id: "continousScroll-container" }));
    };
    exports.default = ContinousScroll;
});
