define(["require", "exports", "preact", "react-i18next"], function (require, exports, preact_1, react_i18next_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var ILPFormatSection = function (props) {
        var t = (0, react_i18next_1.useTranslation)().t;
        return ((0, preact_1.h)("div", null,
            (0, preact_1.h)("div", { className: "oj-sm-only-hide oj-typography-body-xs oj-text-color-secondary oj-sp-card-footer-ellipsis" }, t('Format')),
            (0, preact_1.h)("div", { className: "oj-typography-body-sm oj-text-color-primary oj-sp-card-footer-ellipsis" }, t('Multiple-Choice'))));
    };
    exports.default = ILPFormatSection;
});
