var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
define(["require", "exports", "preact", "lodash", "react", "ojs/ojprogress-circle", "ojs/ojprogress-bar", "./styles.css"], function (require, exports, preact_1, _, react_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.proctorMinuteToDay = void 0;
    _ = __importStar(_);
    var proctorMinuteToDay = function (_num) {
        var num = (typeof _num === 'string') ? parseInt(_num) : _num;
        var days = Math.floor(num / 1440);
        var hours = Math.floor((num - (days * 1440)) / 60);
        var minutes = Math.round(num % 60);
        var html = '';
        if (days > 0) {
            html += days;
            html += days === 1 ? ' day, ' : ' days, ';
        }
        if (hours > 0 || days > 0) {
            html += hours;
            html += hours === 1 ? ' hour, ' : ' hours, ';
        }
        html += minutes;
        html += minutes === 1 ? ' minute' : ' minutes';
        return html;
    };
    exports.proctorMinuteToDay = proctorMinuteToDay;
    var TileContentCollapsed = function (props) {
        if (props === void 0) { props = {}; }
        var node = props.node, clickViewDetails = props.clickViewDetails, int2Duration = props.int2Duration;
        var progressClass = (node === null || node === void 0 ? void 0 : node.totalProgress) > 0 ? ((node === null || node === void 0 ? void 0 : node.totalProgress) === 100 ? 'lp-page-container-completed' : 'lp-page-container-in-progress') : '';
        progressClass = progressClass || ((node === null || node === void 0 ? void 0 : node.completed) === 'Y' ? 'lp-page-container-completed' : '');
        var containerIdPrefix = 'proctorExam';
        var _a = (0, react_1.useState)('View Details'), buttonText = _a[0], setButtonText = _a[1];
        var _b = (0, react_1.useState)('Registered'), registrationStatus = _b[0], setRegistrationStatus = _b[1];
        var _c = (0, react_1.useState)(''), disabledClass = _c[0], setDisabledClass = _c[1];
        var processNode = function (node) {
            var _a, _b, _c, _d;
            var button = 'View Details';
            var registrationStatus = 'Registered';
            var disabledFlag = '';
            var eStatus = (node === null || node === void 0 ? void 0 : node.enrollment_status) ? node === null || node === void 0 ? void 0 : node.enrollment_status.toLowerCase() : null;
            if ((node === null || node === void 0 ? void 0 : node.enrollment_status) == null || ['f', 'c', 'sc', 'pc', 'dn', 'dx', 'dnr', 'df'].includes(eStatus)) {
                registrationStatus = 'Not Registered';
                button = 'Register Now';
            }
            if ((node === null || node === void 0 ? void 0 : node.certStatus) && (node === null || node === void 0 ? void 0 : node.certStatus.toLowerCase()) === 'passed') {
                registrationStatus = 'Passed!';
            }
            else if (((node === null || node === void 0 ? void 0 : node.certStatus) && (node === null || node === void 0 ? void 0 : node.certStatus.toLowerCase()) === 'failed') || (node === null || node === void 0 ? void 0 : node.startsIn) < 0 ||
                (['dn', 'dx', 'dnr', 'df'].includes(eStatus))) {
                button = 'Register Now';
                if ((node === null || node === void 0 ? void 0 : node.availableAttempts) === 2) {
                    registrationStatus = 'Retry exam';
                    if ((node === null || node === void 0 ? void 0 : node.consumption) < ((_a = node === null || node === void 0 ? void 0 : node.rules) === null || _a === void 0 ? void 0 : _a.rules[0].min_2_consumption) && !(node === null || node === void 0 ? void 0 : node.isGuest)) {
                        disabledFlag = 'disabled';
                    }
                }
                else if ((node === null || node === void 0 ? void 0 : node.availableAttempts) === 1) {
                    registrationStatus = 'Retry exam';
                    if ((node === null || node === void 0 ? void 0 : node.consumption) < ((_b = node === null || node === void 0 ? void 0 : node.rules) === null || _b === void 0 ? void 0 : _b.rules[0].min_3_consumption) && !(node === null || node === void 0 ? void 0 : node.isGuest)) {
                        disabledFlag = 'disabled';
                    }
                }
                else if ((node === null || node === void 0 ? void 0 : node.availableAttempts) <= 0) {
                    registrationStatus = '';
                    button = 'Results';
                }
            }
            if ((node === null || node === void 0 ? void 0 : node.availableAttempts) === ((_c = node === null || node === void 0 ? void 0 : node.rules) === null || _c === void 0 ? void 0 : _c.rules[0].max_attempts) && (node === null || node === void 0 ? void 0 : node.consumption) < ((_d = node === null || node === void 0 ? void 0 : node.rules) === null || _d === void 0 ? void 0 : _d.rules[0].min_1_consumption) && !(node === null || node === void 0 ? void 0 : node.isGuest)) {
                disabledFlag = 'disabled';
            }
            setButtonText(button);
            setRegistrationStatus(registrationStatus);
            setDisabledClass(disabledFlag);
        };
        (0, react_1.useEffect)(function () {
            if (node) {
                processNode(node);
            }
        }, [node]);
        var examStatusComponent = ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)("div", { className: "oj-sm-5" },
                (0, preact_1.h)("span", { className: 'oj-lg-padding-4x-start' },
                    (0, preact_1.h)("span", { id: "".concat(containerIdPrefix, "RegistrationStatus-").concat(node === null || node === void 0 ? void 0 : node.id), className: "oj-badge oj-badge-subtle" }, registrationStatus))),
            (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Button-").concat(node === null || node === void 0 ? void 0 : node.id), className: "oj-sm-7  oj-text-right" },
                (0, preact_1.h)("oj-button", { class: "oj-button-md", disabled: disabledClass === 'disabled', chroming: "callToAction", id: "scheduleExam-".concat(node === null || node === void 0 ? void 0 : node.id), onojAction: function (e) { e.preventDefault(); clickViewDetails(node); } }, buttonText))));
        return ((0, preact_1.h)("div", { className: "oj-sm-flex-wrap-nowrap oj-flex11 oj-sp-expandable-list-item-header oj-sm-padding-4x-start oj-sm-padding-2x-end oj-sm-padding-3x-vertical oj-collapsible-header oj-sp-expandable-list-item-header-sticky" }, 
        /* @ts-expect-error */
        (0, preact_1.h)("oj-sp-list-item-template", { class: "oj-sm-12 oj-sp-expandable-list-item-nav-text oj-complete" }, (0, preact_1.h)("div", { className: "lp-page-component-box oj-sm-justify-content-space-between oj-sm-align-items-center oj-flex11 lp-page-component lp-page-comp-exam-proc ".concat(progressClass) },
            (0, preact_1.h)("div", { className: "oj-flex oj-sm-align-items-center" },
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sp-list-item-template-primary-col oj-md-order-2 oj-sm-12 oj-lg-8" },
                    (0, preact_1.h)("div", { className: "oj-flex oj-md-flex-wrap-nowrap oj-sp-list-item-template-column-0" },
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-md-flex-1 oj-sp-list-item-template-flex-shrink oj-sp-list-item-template-main-texts-container" },
                            (0, preact_1.h)("oj-list-item-layout", { class: "oj-sp-list-item-template-list-item-layout oj-complete" },
                                (0, preact_1.h)("div", { className: "oj-listitemlayout-grid" },
                                    (0, preact_1.h)("div", { className: "oj-listitemlayout-textslots" },
                                        (0, preact_1.h)("div", null,
                                            (0, preact_1.h)("div", { className: "oj-flex oj-sm-flex-direction-column" },
                                                (0, preact_1.h)("div", { className: "oj-sp-list-item-template-overline-text oj-flex-item" },
                                                    (0, preact_1.h)("span", { className: "oj-typography-body-xs oj-text-color-secondary oj-typography-bold" }, 'Online Certification Exam')),
                                                (0, preact_1.h)("span", { className: "oj-sp-list-item-template-primary-text oj-flex-item oj-typography-body-md oj-text-color-primary oj-sm-padding-1x-bottom" },
                                                    (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Name-").concat(node === null || node === void 0 ? void 0 : node.id), onClick: typeof (node === null || node === void 0 ? void 0 : node.moocCount) === 'undefined' ? function () { clickViewDetails(node); } : undefined, className: "oj-text-color-primary oj-typography-subheading-xs oj-typography-bold", dangerouslySetInnerHTML: { __html: _.unescape(node === null || node === void 0 ? void 0 : node.name) || '' } })))),
                                        (0, preact_1.h)("div", null,
                                            (0, preact_1.h)("div", { className: "oj-flex-item oj-flex oj-sp-list-item-template-badge-top-container oj-sp-list-item-template-md-badge-fixed " },
                                                (0, preact_1.h)("div", { className: "oj-sp-list-item-template-badge-container" }))),
                                        (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Duration-").concat(node === null || node === void 0 ? void 0 : node.id), className: 'lp-page-course-duration oj-text-left' }, (node === null || node === void 0 ? void 0 : node.duration) && (0, preact_1.h)("span", { className: "oj-flex-item oj-typography-body-xs oj-text-color-secondary oj-typography-bold" }, int2Duration((node === null || node === void 0 ? void 0 : node.duration) || 0))),
                                        (0, preact_1.h)("div", { className: 'oj-sm-margin-2x-bottom oj-sm-margin-2x-top oj-typography-body-sm' },
                                            (0, preact_1.h)("span", null, "You must register, attend and pass this exam to receive your official credential for this certification.")),
                                        (0, preact_1.h)("div", { id: "".concat(containerIdPrefix, "Consumption-").concat(node === null || node === void 0 ? void 0 : node.id), className: 'oj-sm-margin-2x-bottom oj-typography-body-sm oj-typography-bold' },
                                            (0, preact_1.h)("span", null,
                                                "Learning completed: ",
                                                (0, exports.proctorMinuteToDay)((node === null || node === void 0 ? void 0 : node.consumption) || 0))))))))),
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sp-list-item-template-primary-col oj-md-order-3 oj-sm-12 oj-lg-4" },
                    (0, preact_1.h)("div", { className: "oj-flex oj-md-flex-wrap-nowrap oj-sp-list-item-template-column-0" },
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-md-flex-1 oj-sp-list-item-template-flex-shrink oj-sp-list-item-template-main-texts-container" },
                            (0, preact_1.h)("oj-list-item-layout", { class: "oj-sp-list-item-template-list-item-layout oj-complete" },
                                (0, preact_1.h)("div", { className: "oj-listitemlayout-grid" },
                                    (0, preact_1.h)("div", { className: "oj-listitemlayout-textslots oj-sm-justify-content-space-between oj-sm-align-items-center oj-flex" }, node.isLpUserDataLoaded
                                        ? examStatusComponent
                                        : (0, preact_1.h)("oj-progress-circle", { size: "sm", value: -1 }))))))))) /* @ts-expect-error */)));
    };
    function ProctorExamTileBox(props) {
        if (props === void 0) { props = {}; }
        var node = props.node, offeringId = props.offeringId, isFavorite = props.isFavorite, clickViewDetails = props.clickViewDetails, int2Duration = props.int2Duration;
        return ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)(TileContentCollapsed, { node: node, offeringId: offeringId, isFavorite: isFavorite, clickViewDetails: clickViewDetails, int2Duration: int2Duration })));
    }
    exports.default = ProctorExamTileBox;
});
