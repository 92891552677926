define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.getbannedtillDateText = void 0;
    var getbannedtillDateText = function (bannedTill) {
        // if the date is set to 3100-12-31 value, it's a lifetime ban
        // YYYY-MM-DD
        bannedTill = new Date(bannedTill);
        if (bannedTill.getTime() === (new Date('3100-12-31')).getTime()) {
            return 'Lifetime';
        }
        else {
            var options = { month: 'short', day: 'numeric', year: 'numeric' };
            return "Until ".concat(bannedTill.toLocaleDateString('en-us', options));
        }
    };
    exports.getbannedtillDateText = getbannedtillDateText;
});
