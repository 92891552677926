define(["require", "exports", "preact", "../../../../model/index", "./filter-chip.css"], function (require, exports, preact_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    ;
    var FilterChip = function (filterChip) {
        var currentProfile = index_1.Backbone.getInstance().state.app.currentProfile;
        var label = filterChip.label, _a = filterChip.hasRemoveIcon, hasRemoveIcon = _a === void 0 ? false : _a, _b = filterChip.isApplied, isApplied = _b === void 0 ? false : _b, style = filterChip.style, _c = filterChip.onClick, onClick = _c === void 0 ? function () { } : _c;
        return ( /* @ts-expect-error */(0, preact_1.h)("oj-sp-filter-chip", { className: "oj-complete", type: isApplied ? 'applied' : 'notAppiled', label: label, "close-icon": !hasRemoveIcon ? 'never' : '', style: style }, (0, preact_1.h)("div", { className: "oj-sp-filter-chip oj-sp-filter-chip-applied" },
            (0, preact_1.h)("a", { href: "".concat(window.location.origin, "/").concat(currentProfile, "/search/").concat(label), onClick: function (e) { e.preventDefault(); onClick(); } },
                (0, preact_1.h)("div", { className: "oj-sp-filter-chip-chrome" },
                    (0, preact_1.h)("div", { className: "oj-flex oj-sm-flex-wrap-nowrap oj-sm-align-items-center oj-sm-padding-2x-start oj-typography-body-sm oj-sp-helper-white-space-nowrap oj-sp-filter-chip-focusable oj-sp-filter-chip-clickable oj-sm-padding-2x-end oj-sp-filter-chip-applied-actionable" },
                        (0, preact_1.h)("span", { className: "oj-sp-filter-chip-applied-label" }, label),
                        hasRemoveIcon &&
                            (0, preact_1.h)("div", null,
                                (0, preact_1.h)("div", { "aria-orientation": "vertical", className: "oj-sm-margin-2x-start oj-divider-start oj-sp-filter-chip-divider", role: "separator" }),
                                (0, preact_1.h)("a", { className: "oj-flex oj-sm-align-items-center oj-sm-justify-content-center oj-sm-margin-1x-start oj-sp-helper-text-decoration-none oj-sp-filter-chip-close-anchor", href: "#", "aria-lable": "remove", title: "Remove" },
                                    (0, preact_1.h)("span", { "aria-hidden": "true", className: "oj-ux-ico-close oj-ux-icon-size-6x oj-text-color-secondary" }))))))) /* @ts-expect-error */));
    };
    exports.default = FilterChip;
});
