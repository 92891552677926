define(["require", "exports", "preact", "../../model/index"], function (require, exports, preact_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function MotdBanner() {
        var _a = index_1.Backbone.getInstance().state, motd = _a.motd, currentProfile = _a.app.currentProfile;
        var icon = motd.icon, header = motd.header, body = motd.body, _b = motd.detailUrl, detailUrl = _b === void 0 ? '' : _b;
        var icon2color = {
            'oj-ux-ico-alarm-clock': { iconColor: 'oj-icon-color-danger', headerColor: 'oj-text-color-danger', bgColor: 'oj-bg-danger-10' },
            'oj-ux-ico-clock': { iconColor: 'oj-icon-color-danger', headerColor: 'oj-text-color-danger', bgColor: 'oj-bg-danger-10' },
            'oj-ux-ico-close-circle': { iconColor: 'oj-icon-color-danger', headerColor: 'oj-text-color-danger', bgColor: 'oj-bg-danger-10' },
            'oj-ux-ico-check-circle': { iconColor: 'oj-icon-color-success', headerColor: 'oj-text-color-success', bgColor: 'oj-bg-success-10' },
            'oj-ux-ico-warning': { iconColor: 'oj-icon-color-warning', headerColor: 'oj-text-color-warning', bgColor: 'oj-bg-warning-10' },
            'oj-ux-ico-help': { iconColor: 'oj-icon-color-info', headerColor: 'oj-text-color-primary', bgColor: 'oj-bg-info-10' }
        };
        var _c = icon2color[icon] || { iconColor: 'oj-icon-color-danger', headerColor: 'oj-text-color-danger', bgColor: 'oj-bg-danger-10' }, iconColor = _c.iconColor, headerColor = _c.headerColor, bgColor = _c.bgColor;
        return ((0, preact_1.h)("div", { className: bgColor + ' oj-sm-padding-4x-horizontal' },
            (0, preact_1.h)("div", { className: "oj-md-padding-6x-horizontal oj-lg-padding-8x-horizontal oj-sm-padding-3x-top oj-sm-padding-3x-bottom oj-md-padding-5x-top oj-md-padding-5x-bottom" },
                (0, preact_1.h)("div", { className: "oj-flex-bar" },
                    icon.length > 0 &&
                        (0, preact_1.h)("div", { className: iconColor + ' oj-sm-padding-2x-end' },
                            (0, preact_1.h)("span", { className: 'oj-ux-icon-size-6x ' + icon, role: "img" })),
                    header.length > 0 &&
                        (0, preact_1.h)("h1", { className: headerColor + ' oj-typography-subheading-xs' }, header)),
                body.length > 0 &&
                    (0, preact_1.h)("div", { className: "oj-flex-bar" },
                        (0, preact_1.h)("p", null, body)),
                detailUrl.length > 0 &&
                    (0, preact_1.h)("div", { className: "oj-flex-bar" },
                        (0, preact_1.h)("p", null,
                            (0, preact_1.h)("a", { href: "/".concat(currentProfile).concat(detailUrl), rel: "noreferrer", target: "_blank", className: "oj-typography-body-sm oj-typography-semi-bold oj-text-color-primary" }, "See detail"))))));
    }
    exports.default = MotdBanner;
});
