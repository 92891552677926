var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
define(["require", "exports", "preact", "preact/hooks"], function (require, exports, preact_1, hooks_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var BCTranscriptComponent = function (props) {
        var p3sdkA = {
            label_when_locked: '<span class="oj-ux-ico-lock"></span>',
            label_when_unlocked: '<span class="oj-ux-ico-lock-open"></span>'
        };
        var _a = (0, hooks_1.useState)({
            file_id: '',
            project_id: '10650'
        }), p3sdkTranscriptAttr = _a[0], setP3sdkTranscriptAttr = _a[1];
        (0, hooks_1.useEffect)(function () {
            if (props.fileId) {
                setP3sdkTranscriptAttr({
                    file_id: props.fileId,
                    project_id: '10650'
                });
            }
            else {
                setP3sdkTranscriptAttr({
                    file_id: '',
                    project_id: '10650'
                });
            }
        }, [props.fileId]);
        return (0, preact_1.h)("div", null,
            (0, preact_1.h)("div", { id: "3p-plugin-target-".concat(p3sdkTranscriptAttr.file_id, "-oytt604c"), className: 'p3sdk-target' },
                (0, preact_1.h)("div", __assign({ className: 'p3sdk-interactive-transcript' }, p3sdkTranscriptAttr),
                    (0, preact_1.h)("div", { className: 'topbar-background' },
                        (0, preact_1.h)("div", { className: "oj-flex" },
                            (0, preact_1.h)("oj-input-search", { id: "search1", class: "oj-form-control-max-width-lg p3sdk-interactive-transcript-searchbox", placeholder: "Search transcript", "aria-label": "Search Transcript" })),
                        (0, preact_1.h)("div", { className: "oj-flex-bar oj-sm-padding-3x-top oj-header-border" },
                            (0, preact_1.h)("div", { className: "oj-flex-bar-start" },
                                (0, preact_1.h)("h4", { className: "oj-typography-body-xs oj-typography-bold" },
                                    (0, preact_1.h)("a", { href: 'javascript:void(0);', className: 'p3sdk-anchor-tags p3sdk-interactive-transcript-toggle-keywords-button', title: 'keywords' },
                                        (0, preact_1.h)("span", null, "[A]"),
                                        " ",
                                        (0, preact_1.h)("span", null, "Keywords")))),
                            (0, preact_1.h)("div", { className: "oj-flex-bar-end" },
                                (0, preact_1.h)("h4", { className: "oj-lg-padding-4x-start oj-typography-body-xs oj-typography-bold" },
                                    (0, preact_1.h)("a", __assign({ href: 'javascript:void(0);', className: 'p3sdk-anchor-tags p3sdk-scroll-toggle p3sdk-scroll-toggle-locked' }, p3sdkA, { title: 'scroll lock' }),
                                        (0, preact_1.h)("span", null, "Lock")))))),
                    (0, preact_1.h)("div", { className: 'p3sdk-interactive-transcript-content', tabIndex: 0 }))));
    };
    exports.default = BCTranscriptComponent;
});
