define(["require", "exports", "preact", "preact/hooks", "./styles.css", "ojs/ojtreeview", "ojs/ojtoolbar", "ojs/ojmenu", "ojs/ojbutton", "ojs/ojlabel", "ojs/ojinputsearch", "ojs/ojoption", "ojs/ojmenuselectmany"], function (require, exports, preact_1, hooks_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var BannerMenuBar = function (props) {
        var _a = (0, hooks_1.useState)(['singlePage']), menuValues = _a[0], setMenuValues = _a[1];
        function handleClick(event) {
            var menus = menuValues;
            if (!menus.includes(event.detail.selectedValue)) {
                menus.push(event.detail.selectedValue);
            }
            else {
                menus.splice(menus.indexOf(event.detail.selectedValue), 1);
            }
            switch (event.detail.selectedValue) {
                case 'contScroll':
                    props.setContinousScroll(function (prev) { return !prev; });
                    menus.splice('twoPages', 1);
                    break;
                case 'toc':
                    props.onBookmarks(function (prev) { return !prev; });
                    props.setThumbnailShow(false);
                    var index1 = menus.indexOf('thumb');
                    if (index1 > -1) {
                        menus.splice(index1, 1);
                    }
                    break;
                case 'thumb':
                    props.setThumbnailShow(function (prev) { return !prev; });
                    props.onBookmarks(false);
                    var index2 = menus.indexOf('toc');
                    if (index2 > -1) {
                        menus.splice(index2, 1);
                    }
                    break;
                case 'twoPages':
                    props.onTwopageToggle(function (prev) {
                        if (prev === true) {
                            menus.push('singlePage');
                        }
                        else {
                            var index3 = menus.indexOf('singlePage');
                            if (index3 > -1) {
                                menus.splice(index3, 1);
                            }
                        }
                        return !prev;
                    });
                    break;
                case 'singlePage':
                    props.onTwopageToggle(function (prev) {
                        if (prev === false) {
                            menus.push('twoPages');
                        }
                        else {
                            var index4 = menus.indexOf('twoPages');
                            if (index4 > -1) {
                                menus.splice(index4, 1);
                            }
                        }
                        return !prev;
                    });
                    break;
            }
            setMenuValues(menus);
        }
        function enterFullScreen() {
            props.setEnableCopy(false);
            if (props.fullScreen) {
                var element = document.getElementsByTagName('body')[0];
                element.requestFullscreen();
            }
            else {
                document.exitFullscreen();
            }
        }
        ;
        (0, hooks_1.useEffect)(function () {
            document.addEventListener('fullscreenchange', function (event) {
                props.setFullScreen(function (prev) { return !prev; });
            });
        }, []);
        (0, hooks_1.useEffect)(function () {
            if (!props.landScapePdf || (props.landScapePdf && !props.twoPageShow)) {
                var canvas = document.getElementById('the-canvas');
                var canvasTwo = document.getElementById('second-canvas');
                if (props.fullScreen) {
                    props.setZoom(80);
                }
                else {
                    props.setZoom(100);
                }
                if (!props.landScapePdf) {
                    canvas.style.width = '';
                    canvas.style.height = '';
                }
                if (props.twoPageShow) {
                    canvasTwo.style.width = '';
                    canvasTwo.style.height = '';
                    canvasTwo.style.position = '';
                    canvas.classList.remove('canvas-absolute');
                }
            }
        }, [props.fullScreen]);
        (0, hooks_1.useEffect)(function () {
            var menus = menuValues;
            var index1 = menus.indexOf('thumb');
            if (index1 > -1) {
                menus.splice(index1, 1);
            }
            var index2 = menus.indexOf('toc');
            if (index2 > -1) {
                menus.splice(index2, 1);
            }
            setMenuValues(menus);
        }, [props.clickMobileNavigate]);
        return ((0, preact_1.h)("header", { role: "banner", className: "oj-web-applayout-header csp-header-bgc pdf-header-banner" },
            (0, preact_1.h)("div", { className: "oj-flex-bar oj-sm-align-items-center" },
                (0, preact_1.h)("div", { className: "oj-flex-bar-start oj-sm-align-items-center" },
                    (0, preact_1.h)("oj-menu-button", { chroming: "borderless", class: "oj-button-sm ekit-menu", title: 'Menu', display: "icons" },
                        (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-layout-2-col oj-ux-icon-size-4x oj-link-standalone oj-link-subtle-primary oj-sm-padding-1x-horizontal " }),
                        (0, preact_1.h)("oj-menu", { id: "menu", slot: "menu", style: "display:none", onojMenuAction: handleClick, class: "ekit-menu-options" },
                            (0, preact_1.h)("oj-menu-select-many", { value: menuValues },
                                (0, preact_1.h)("oj-option", { value: "toc" }, "Table of Contents"),
                                (0, preact_1.h)("oj-option", { value: "thumb" }, "Thumbnail"),
                                (0, preact_1.h)("oj-option", null, "---"),
                                (0, preact_1.h)("oj-option", { value: "singlePage" }, "Single Page"),
                                (0, preact_1.h)("oj-option", { value: "twoPages" }, "Two Pages")))),
                    (0, preact_1.h)("h1", { className: "oj-sm-only-hide oj-typography-body-sm\r\n          oj-xl-margin-2x-start oj-sm-margin-2x-start oj-md-margin-5x-start oj-lg-margin-5x-start\r\n          oj-xl-margin-0-bottom oj-lg-margin-0-bottom oj-md-margin-0-bottom titleContent", title: props.fileName, style: "color: rgb(255, 255, 255);" }, props.fileName)),
                (0, preact_1.h)("div", { className: "oj-flex-bar-middle oj-sm-justify-content-center oj-sm-align-items-center" },
                    (0, preact_1.h)("div", { className: "oj-flex oj-sm-align-items-center oj-sm-justify-content-center oj-sm-flex-items-initial" },
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-only-hide" },
                            (0, preact_1.h)("oj-button", { chroming: "borderless", class: "oj-button-sm", display: "icons", title: 'Zoom out', onojAction: function () { return props.onZoompage(-10); } },
                                (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-zoom-out oj-ux-icon-size-4x oj-link-standalone oj-link-subtle-primary csp-logo" }),
                                (0, preact_1.h)("span", null))),
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-xl-padding-2x-end oj-sm-only-hide" },
                            (0, preact_1.h)("oj-input-text", { "label-hint": "Percentage", "label-edge": "none", placeholder: "", class: "csp-search-text-info", id: "zoomText", style: "width: 2.75rem", value: props.percentValue + '%', disabled: true, onKeyPress: function (e) {
                                    var _a;
                                    if (e.charCode === 13) {
                                        props.onZoompage(parseInt((_a = e.target) === null || _a === void 0 ? void 0 : _a.value));
                                    }
                                } })),
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-only-hide" },
                            (0, preact_1.h)("oj-button", { chroming: "borderless", class: "oj-button-sm", display: "icons", title: 'Zoom in', onojAction: function () { return props.onZoompage(10); } },
                                (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-zoom-in oj-ux-icon-size-4x oj-link-standalone oj-link-subtle-primary csp-logo" }),
                                (0, preact_1.h)("span", null))),
                        (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-only-hide" }, props.fullScreen
                            ? (0, preact_1.h)("oj-button", { chroming: "borderless", class: "oj-button-sm", display: "icons", title: 'Full Screen', onojAction: function () { return enterFullScreen(); } },
                                (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-maximize oj-ux-icon-size-4x oj-link-standalone oj-link-subtle-primary csp-logo" }),
                                (0, preact_1.h)("span", null))
                            : (0, preact_1.h)("oj-button", { chroming: "borderless", class: "oj-button-sm", display: "icons", title: 'Exit Full Screen', onojAction: function () { return enterFullScreen(); } },
                                (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-minimize oj-ux-icon-size-4x oj-link-standalone oj-link-subtle-primary csp-logo" }),
                                (0, preact_1.h)("span", null))),
                        (0, preact_1.h)("div", { className: "oj-flex-item" },
                            (0, preact_1.h)("oj-button", { chroming: "borderless", class: "oj-button-sm", display: "icons", title: props.continousScroll ? 'Disable Continuous Scroll for copy' : 'Enable Copy', disabled: props.continousScroll, hidden: !props.enableCopy, onojAction: function () { return props.copyText(); } },
                                (0, preact_1.h)("span", { slot: "startIcon", className: " oj-ux-ico-copy oj-ux-icon-size-4x oj-link-standalone oj-link-subtle-primary csp-logo" }),
                                (0, preact_1.h)("span", null))))),
                (0, preact_1.h)("div", { className: "oj-flex-bar-end oj-sm-align-items-center" },
                    (0, preact_1.h)("div", { className: "oj-flex oj-sm-align-items-center" },
                        (0, preact_1.h)("div", { className: "oj-flex-items" },
                            (0, preact_1.h)("oj-input-text", { "label-hint": "Search", "label-edge": "none", placeholder: "Search", class: "csp-search-text-info", onKeyPress: function (e) {
                                    var _a;
                                    if (e.charCode === 13) {
                                        props.searchContent((_a = e.target) === null || _a === void 0 ? void 0 : _a.value);
                                    }
                                } },
                                (0, preact_1.h)("div", { slot: "start", className: "oj-text-field-start-end-icon oj-ux-ico-search oj-ux-icon-size-4x", role: "img", title: "search icon" }))))))));
    };
    exports.default = BannerMenuBar;
});
