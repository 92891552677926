define(["require", "exports", "preact", "preact/hooks", "../../../shared.mod/enums/index", "./styles.css"], function (require, exports, preact_1, hooks_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var BadgeContent = function (props) {
        if (props === void 0) { props = {}; }
        var _a = props.nodeData, nodeData = _a === void 0 ? {} : _a, badgeType = props.badgeType;
        return ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)("div", { className: "oj-flex tile-header" },
                (0, preact_1.h)("div", { className: 'oj-flex-item' },
                    (0, preact_1.h)("div", { className: badgeType === index_1.BadgeType.Certification ? 'cert-badge' : 'badge', role: "button", style: { backgroundImage: "url(".concat(nodeData.image, ")") } })))));
    };
    var BadgeContentFlipped = function (props) {
        if (props === void 0) { props = {}; }
        var _a = props.nodeData, nodeData = _a === void 0 ? {} : _a;
        return ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)("div", { className: "oj-flex tile-header" },
                (0, preact_1.h)("div", { className: 'oj-flex-item' },
                    (0, preact_1.h)("div", { role: "button", className: "flipped-badge" },
                        "Details:",
                        (0, preact_1.h)("br", null),
                        (0, preact_1.h)("br", null),
                        (0, preact_1.h)("b", null, nodeData.name),
                        (0, preact_1.h)("br", null),
                        (0, preact_1.h)("br", null),
                        "Earned: ",
                        nodeData.date,
                        (0, preact_1.h)("br", null),
                        (0, preact_1.h)("br", null),
                        (0, preact_1.h)("br", null),
                        "Share:",
                        (0, preact_1.h)("br", null),
                        (0, preact_1.h)("br", null),
                        "LinkedIn | Twitter | Facebook",
                        (0, preact_1.h)("br", null),
                        (0, preact_1.h)("br", null),
                        (0, preact_1.h)("br", null))))));
    };
    function Badge(props) {
        if (props === void 0) { props = {}; }
        var _a = (0, hooks_1.useState)(false), flipped = _a[0], setFlipped = _a[1];
        var _b = props.nodeData, nodeData = _b === void 0 ? {} : _b, badgeType = props.badgeType;
        return ((0, preact_1.h)("div", { onMouseEnter: function () { setFlipped(true); }, onMouseLeave: function () { setFlipped(false); } }, !flipped ? (0, preact_1.h)(BadgeContent, { nodeData: nodeData, badgeType: badgeType }) : (0, preact_1.h)(BadgeContentFlipped, { nodeData: nodeData })));
    }
    exports.default = Badge;
});
