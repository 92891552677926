define(["require", "exports", "preact", "../../../shared.mod/index", "../../../shared.mod/enums/index", "../../../../model/index"], function (require, exports, preact_1, index_1, index_2, index_3) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var StartLearning = function (props) {
        var _a = index_3.Backbone.getInstance().state, userProfile = _a.userProfile, frontend = _a.learnerRoleProfile.frontend;
        var _b = (userProfile || {}).user, user = _b === void 0 ? {} : _b;
        var settings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            speed: 500,
            centerMode: false,
            centerPadding: '100px',
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 1690,
                    settings: {
                        slidesToShow: 4,
                        centerPadding: '10px'
                    }
                },
                {
                    breakpoint: 1380,
                    settings: {
                        rows: 2,
                        slidesToShow: 2,
                        centerPadding: '10px'
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        rows: 4,
                        slidesToShow: 1,
                        centerPadding: '0'
                    }
                }
            ]
        };
        return ((0, preact_1.h)("div", null,
            (0, preact_1.h)("h2", { className: "oj-sp-typography-display-6", style: { display: 'inline-block' } }, (user === null || user === void 0 ? void 0 : user.isGuest) ? frontend.discoverYourPathTitle.guest : frontend.discoverYourPathTitle.default),
            (0, preact_1.h)(index_1.TileboxCarousel, { title: '', hits: { hits: frontend.discoverYourPath.values }, cardType: index_2.CardType.LargeImageCard, settings: settings, isStartLearning: true })));
    };
    exports.default = StartLearning;
});
