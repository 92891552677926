define(["require", "exports", "preact", "./spinner.css"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var Spinner = function (props) {
        return ((0, preact_1.h)("div", { className: "spinner" },
            (0, preact_1.h)("div", { className: "middle" },
                (0, preact_1.h)("span", { className: 'loading-content ' + props.size }))));
    };
    exports.default = Spinner;
});
