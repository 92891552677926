define(["require", "exports", "preact", "preact/hooks"], function (require, exports, preact_1, hooks_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function isIframe(input) {
        return input !== null && input.tagName === 'IFRAME';
    }
    function getIframe(id) {
        var iframe = document.getElementById(id);
        if (!isIframe(iframe)) {
            throw new Error('missing iframe');
        }
        return iframe;
    }
    function ASXDialog(props) {
        function handleMessage(ev) {
            if (ev.data.action === 'CLOSE_ASX_EXAM' && props.close) {
                props.close();
            }
        }
        function beforeClose() {
            var _a;
            try {
                (_a = getIframe('aSXIframe').contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({ action: 'ASX_SUBMIT_EXAM' }, '*');
            }
            catch (err) { }
        }
        function onLoad() {
            window.addEventListener('message', handleMessage);
            props.onLoad && props.onLoad();
        }
        (0, hooks_1.useEffect)(function () {
            return function () { return window.removeEventListener('message', handleMessage); };
        }, []);
        return (0, preact_1.h)("div", { id: "aSXDialogWrapper" },
            (0, preact_1.h)("oj-dialog", { id: "aSXDialog", onojBeforeClose: beforeClose, style: { height: '90vh', width: '90vw' } },
                (0, preact_1.h)("div", { slot: "header" }),
                (0, preact_1.h)("div", { slot: "body", style: { height: '100%' } }, props.src && (0, preact_1.h)("iframe", { id: "aSXIframe", title: "ASX iframe", src: props.src, style: {
                        border: 'none',
                        height: '99%',
                        width: '100%'
                    }, onLoad: onLoad }))));
    }
    exports.default = ASXDialog;
});
