define(["require", "exports", "preact", "../../model/index", "react", "./css/styles.css"], function (require, exports, preact_1, index_1, react_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function Footer() {
        (0, react_1.useEffect)(function () {
            var script = document.createElement('script');
            script.src = (window.location.protocol === 'http:') ? '/consent-trustarc.js' : 'https://consent.trustarc.com/notice?domain=oracle.com&c=teconsent&text=true&gtm=1&cdn=1&pcookie';
            script.async = true;
            script.id = 'consentScript';
            script.crossOrigin = '';
            document.head.appendChild(script);
        }, []);
        var frontend = index_1.Backbone.getInstance().state.learnerRoleProfile.frontend;
        return ((0, preact_1.h)("footer", { className: "vl-footer uk-section uk-section-secondary uk-section-small uk-text-xsmall uk-text-muted" },
            (0, preact_1.h)("div", { className: "uk-container uk-container-expand" },
                (0, preact_1.h)("ul", { className: "uk-subnav uk-flex-center" },
                    (0, preact_1.h)("li", null,
                        (0, preact_1.h)("a", { "data-lbl": "copyright", rel: "noreferrer", target: "_blank", href: "https://www.oracle.com/legal/copyright.html" },
                            "\u00A9 ",
                            (new Date()).getFullYear(),
                            " Oracle University")),
                    (0, preact_1.h)("li", null,
                        (0, preact_1.h)("a", { "data-lbl": "privacy", rel: "noreferrer", target: "_blank", href: "https://www.oracle.com/legal/privacy/" }, "Privacy"),
                        "\u00A0",
                        (0, preact_1.h)("span", null, "/"),
                        "\u00A0",
                        (0, preact_1.h)("a", { style: { color: '#fcfbfa' }, "data-lbl": "do-not-sell-my-info", rel: "noreferrer", target: "_blank", href: "https://www.oracle.com/legal/privacy/privacy-choices.html" }, "Do Not Sell My Info")),
                    (0, preact_1.h)("li", null,
                        (0, preact_1.h)("a", { href: frontend.contactUsUrl.default, rel: "noreferrer", target: "_blank" }, "Contact Us")),
                    (0, preact_1.h)("li", null,
                        (0, preact_1.h)("a", { href: frontend.termsAndConditionsUrl.default, rel: "noreferrer", target: "_blank" }, "Terms of Use")),
                    (0, preact_1.h)("li", null,
                        (0, preact_1.h)("div", { id: "teconsent" })),
                    (0, preact_1.h)("li", { className: "u10last" },
                        (0, preact_1.h)("a", { rel: "noreferrer", target: "_blank", href: "https://www.oracle.com/legal/privacy/marketing-cloud-data-cloud-privacy-policy.html#13" }, "Ad Choices"))))));
    }
    exports.default = Footer;
});
