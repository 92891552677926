define(["require", "exports", "preact"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var ExamDate = function (_a) {
        var examDate = _a.examDate;
        return ((0, preact_1.h)("div", { className: "oj-flex-item" },
            (0, preact_1.h)("div", { className: "oj-sm-only-hide oj-typography-body-xs oj-text-color-secondary oj-sp-card-footer-ellipsis" }, "Exam Date"),
            (0, preact_1.h)("div", { className: "oj-typography-body-sm oj-text-color-primary oj-sp-card-footer-ellipsis" }, examDate)));
    };
    exports.default = ExamDate;
});
