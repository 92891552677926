define(["require", "exports", "preact", "./styles.css", "ojs/ojtreeview", "ojs/ojinputtext", "ojs/ojlistview"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var SearchResultComponent = function (props) {
        var _a;
        var handleClick = function (index) {
            props.setNowPage(index);
            if (window.innerWidth < 768 && window.innerHeight < 1024) {
                // It's likely a mobile device
                props.setSearchResultShow(false);
            }
        };
        return ((0, preact_1.h)("div", null,
            (0, preact_1.h)("div", { className: "oj-flex oj-sm-justify-content-center oj-sm-align-items-center\n        oj-xl-padding-4x-horizontal oj-xl-padding-4x-top oj-sm-padding-4x-horizontal oj-sm-padding-4x-top" },
                (0, preact_1.h)("div", { className: "oj-flex-item" },
                    (0, preact_1.h)("div", { className: "oj-typography-subheading-xs oj-text-color-primary" }, "Search results")),
                (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-flex-0" },
                    (0, preact_1.h)("oj-button", { id: "buttonCloser", display: "icons", chroming: "borderless", class: "oj-button-sm", onClick: function () { return props.setSearchResultShow(false); } },
                        (0, preact_1.h)("span", { slot: "endIcon", className: "oj-ux-ico-close" })))),
            (0, preact_1.h)("hr", null),
            (0, preact_1.h)("div", { className: "oj-flex ekit-toc-container oj-xl-padding-4x-horizontal oj-sm-padding-4x-horizontal oj-sm-padding-4x-top" },
                (0, preact_1.h)("oj-list-view", { "selection-mode": "single", display: "list", scrollPolicy: "loadAll", class: "oj-sm-padding-0 ekit-listView", id: "ekit-search-list" }, 
                // eslint-disable-next-line array-callback-return
                (_a = props.searchResults) === null || _a === void 0 ? void 0 : _a.map(function (result, index) {
                    if (result.items.length > 0) {
                        return (0, preact_1.h)("li", { key: index, className: "oj-line-clamp-2 oj-typography-body-xs oj-xl-padding-3x", onClick: function () { return handleClick(result.page + 1); } },
                            (0, preact_1.h)("span", { className: "oj-typography-bold" },
                                "Page: ",
                                result.page + 1),
                            (0, preact_1.h)("span", null, result.items[0]));
                    }
                })))));
    };
    exports.default = SearchResultComponent;
});
