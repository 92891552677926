define(["require", "exports", "preact", "preact/hooks"], function (require, exports, preact_1, hooks_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    function NotFound() {
        (0, hooks_1.useEffect)(function () {
            window.location.href = '/home';
        }, []);
        return ((0, preact_1.h)("div", null, "Page Not Found"));
    }
    exports.default = NotFound;
});
