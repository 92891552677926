define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var env = {
        OLS_HOME: 'https://learn.oracle.com',
        LOCAL_HOME: 'https://mylearn.oracle.com',
        MAIN_ENV: 'mlprod',
        IMG_URL: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/PROD',
        ILP_BASE_LOGIN_URL: 'https://idcs-login.identity.oraclecloud.com/fed/v1/idp/initiatesso?providerid=https://sso4.seertechsolutions.com/opensaml-sp&returnurl=deeplinkplayerv2%3D',
        THIRD_PARTY_COOKIE_URL: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/html/PROD/create-third-party-cookie.html'
    };
    exports.default = env;
});
