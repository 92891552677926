define(["require", "exports", "preact", "ojs/ojavatar"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.HeaderAvatar = exports.ProfileAvatar = void 0;
    function ProfileAvatar(props) {
        var user = props.user;
        var firstName = user.firstName, lastName = user.lastName;
        var initials = firstName[0].toUpperCase() + lastName[0].toUpperCase();
        return ((0, preact_1.h)("oj-avatar", { style: { marginTop: 60 }, role: "img", size: "2xl", initials: initials, "aria-label": initials, title: initials }));
    }
    exports.ProfileAvatar = ProfileAvatar;
    function HeaderAvatar(props) {
        var _a = props.user, user = _a === void 0 ? {} : _a;
        var _b = user.firstName, firstName = _b === void 0 ? ' ' : _b, _c = user.lastName, lastName = _c === void 0 ? ' ' : _c;
        var initials = firstName[0].toUpperCase() + lastName[0].toUpperCase();
        return ((0, preact_1.h)(preact_1.Fragment, null, (user === null || user === void 0 ? void 0 : user.isGuest)
            ? ((0, preact_1.h)("div", { className: "oj-navigation-guest-account oj-navigationlist-item-content" },
                (0, preact_1.h)("span", { className: "oj-navigationlist-item-icon oj-ux-ico-avatar", style: { fontSize: '1.3rem', textAlign: 'center' } }),
                (0, preact_1.h)("span", { className: "oj-navigationlist-item-label" }, "Account")))
            : ((0, preact_1.h)("oj-avatar", { role: "img", size: "2xs", initials: initials, "aria-label": initials, title: initials, background: "orange" }))));
    }
    exports.HeaderAvatar = HeaderAvatar;
});
