define(["require", "exports", "preact", "ojs/ojbutton", "ojs/ojinputtext"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var PrivateEventForm = function (props) {
        var eventCode = props.eventCode, validCode = props.validCode, handleCodeChange = props.handleCodeChange, handleApplyPrivateEventCode = props.handleApplyPrivateEventCode, isSlotsLoading = props.isSlotsLoading;
        return ((0, preact_1.h)("div", { className: "oj-md-12 oj-sm-padding-2x-horizontal oj-sm-only-margin-2x-bottom" },
            (0, preact_1.h)("oj-input-text", { autofocus: true, "label-edge": "inside", clearIcon: "conditional", length: { countBy: 'codeUnit', max: 6 }, class: "oj-form-control-max-width-md", "label-hint": 'Private Event Code', "aria-label": "Enter private event code", value: eventCode, onrawValueChanged: function (e) { return handleCodeChange(e); }, disabled: isSlotsLoading }),
            (0, preact_1.h)("oj-button", { chroming: "callToAction", id: "applyPrivateEvent", class: "oj-md-margin-4x-start oj-md-margin-2x-top oj-sm-margin-2x-top", "aria-label": "Apply private event code", disabled: !validCode, onojAction: function () { return handleApplyPrivateEventCode(); }, key: "pacApplyButton" }, 'Apply')));
    };
    exports.default = PrivateEventForm;
});
