define(["require", "exports", "preact"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var NetSuiteLab = function () {
        var netSuiteLabUrl = 'https://nlcorp.extforms.netsuite.com/app/site/crm/externalcustrecordpage.nl/compid.NLCORP/.f?formid=8256&h=AAFdikaIIsNiQCQ-XSKownV3tsVH3NXwAG7sgaa4XMFORgu3an8&redirect_count=1&did_javascript_redirect=T';
        return ((0, preact_1.h)("div", { className: "oj-flex oj-md-padding-5x-start oj-md-padding-5x-end oj-sm-padding-4x-end", id: "labDetails" },
            (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-padding-8x-bottom oj-md-only-padding-12x-start oj-sm-only-padding-4x-start" },
                (0, preact_1.h)("oj-accordion", { id: "a1", class: "items-list-conatiner", multiple: true },
                    (0, preact_1.h)("oj-collapsible", { id: "c1", expanded: true },
                        (0, preact_1.h)("div", { slot: "header", className: "stepHeader" },
                            (0, preact_1.h)("h4", { className: "oj-typography-subheading-xs" }, '1: Request Your Lab')),
                        (0, preact_1.h)("div", { className: "oj-flex oj-sm-flex-direction-column oj-bg-neutral-20 oj-sm-padding-4x" },
                            (0, preact_1.h)("div", { className: "oj-flex-item lab-msg-common", id: "netSuiteReqLab" },
                                (0, preact_1.h)("p", { className: "lab-msg-common" }, "Want to perform hands-on activities? Get started by requesting your lab. Please note response to this request will be returned within two (2) business days.")),
                            (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-margin-4x-top oj-sm-align-self-center lab-msg-common", id: "netSuiteReqLabBtn" },
                                (0, preact_1.h)("a", { href: netSuiteLabUrl, target: '_blank', rel: 'noreferrer' },
                                    (0, preact_1.h)("oj-button", { chroming: "callToAction", id: "netSuiteReqLab", "aria-label": 'Request lab', class: "oj-button-sm oj-sm-margin-4x-bottom" }, 'Request Lab')))))))));
    };
    exports.default = NetSuiteLab;
});
