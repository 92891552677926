define(["require", "exports", "preact", "./styles.css", "ojs/ojtreeview", "ojs/ojbutton"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var BottomMenuBar = function (props) {
        return ((0, preact_1.h)("footer", { className: "oj-web-applayout-footer csp-footer-bgc oj-xl-padding-4x-bottom oj-lg-padding-3x-bottom oj-md-padding-3x-bottom oj-sm-padding-3x-bottom", role: "contentinfo", style: "min-height: 0px" },
            (0, preact_1.h)("div", { className: "oj-web-applayout-footer-item oj-typography-body-sm oj-sm-align-items-center oj-sm-justify-content-center" },
                (0, preact_1.h)("div", { className: "oj-flex oj-sm-align-items-center oj-sm-justify-content-center oj-sm-flex-items-initial" },
                    (0, preact_1.h)("div", { className: "oj-flex-item" },
                        (0, preact_1.h)("oj-button", { chroming: "borderless", class: "oj-button-sm", display: "icons", title: 'First page', style: { opacity: props.nowPage === 1 ? 0.4 : 1 }, onClick: function () { return !props.downloading && props.setNowPage(1); } },
                            (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-chevron-left-end oj-ux-icon-size-4x oj-link-standalone oj-link-subtle-primary oj-sm-padding-2x-end csp-logo" }))),
                    (0, preact_1.h)("div", { className: "oj-flex-item" },
                        (0, preact_1.h)("oj-button", { chroming: "borderless", class: "oj-button-sm", display: "icons", title: 'Prev page', style: { opacity: props.nowPage === 1 ? 0.4 : 1 }, onClick: function () {
                                if ((props.currentPage === 2 && props.twoPageShow) || props.downloading)
                                    return;
                                props.onChange(-1);
                            } },
                            (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-chevron-left oj-ux-icon-size-4x oj-link-standalone oj-link-subtle-primary oj-sm-padding-2x-end csp-logo" }))),
                    (0, preact_1.h)("div", { className: "oj-flex-item oj-xl-padding-2x-end" },
                        (0, preact_1.h)("div", { className: "oj-flex oj-sm-align-items-center oj-sm-justify-content-center" },
                            (0, preact_1.h)("div", { className: "oj-flex-item oj-xl-margin-1x-end" },
                                (0, preact_1.h)("oj-input-text", { "label-hint": "PageNo", "label-edge": "none", placeholder: "", value: props.currentPage, onKeyPress: function (e) {
                                        var _a;
                                        if (e.charCode === 13) {
                                            !props.downloading && props.setNowPage(parseInt((_a = e.target) === null || _a === void 0 ? void 0 : _a.value));
                                        }
                                    }, class: "csp-search-text-info", style: "width: 2rem" })),
                            (0, preact_1.h)("div", { className: "oj-flex-item" },
                                (0, preact_1.h)("span", null,
                                    " / ",
                                    props.totalPages)))),
                    (0, preact_1.h)("div", { className: "oj-flex-item" },
                        (0, preact_1.h)("oj-button", { chroming: "borderless", class: "oj-button-sm", display: "icons", title: 'Next page', style: { opacity: props.nowPage === props.totalPages ? 0.4 : 1 }, onClick: function () {
                                if ((props.currentPage === props.totalPages - 1 && props.twoPageShow) || props.downloading)
                                    return;
                                props.onChange(1);
                            } },
                            (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-chevron-right oj-ux-icon-size-4x oj-link-standalone oj-link-subtle-primary oj-sm-padding-2x-end csp-logo" }))),
                    (0, preact_1.h)("div", { className: "oj-flex-item" },
                        (0, preact_1.h)("oj-button", { chroming: "borderless", class: "oj-button-sm", display: "icons", title: 'Last page', style: { opacity: props.nowPage === props.totalPages ? 0.4 : 1 }, onClick: function () {
                                !props.downloading && props.setNowPage(props.totalPages);
                            } },
                            (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-chevron-right-end oj-ux-icon-size-4x oj-link-standalone oj-link-subtle-primary oj-sm-padding-2x-end csp-logo" })))))));
    };
    exports.default = BottomMenuBar;
});
