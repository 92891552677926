define(["require", "exports", "preact", "ojs/ojprogress-bar"], function (require, exports, preact_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var SaasPbtExamScore = function (props) {
        var saasUserGrade = props.saasUserGrade, skillableUserGrade = props.skillableUserGrade;
        document.getElementById('skillableScoreProgressBar');
        document.getElementById('saasScoreProgressBar');
        var skillableIconClass = skillableUserGrade === 'PASSED' ? 'oj-icon-color-success oj-ux-ico-success-s oj-ux-icon-size-6x' : 'oj-icon-color-danger oj-ux-ico-error-s oj-ux-icon-size-6x';
        var saasIconClass = saasUserGrade === 'PASSED' ? 'oj-icon-color-success oj-ux-ico-success-s oj-ux-icon-size-6x' : 'oj-icon-color-danger oj-ux-ico-error-s oj-ux-icon-size-6x';
        return ((0, preact_1.h)(preact_1.Fragment, null,
            (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-padding-4x oj-panel oj-md-margin-8x-start oj-sm-margin-2x-bottom" },
                (0, preact_1.h)("div", null, "Your Challenges Result"),
                (0, preact_1.h)("div", { className: "oj-typography-subheading-lg oj-sm-padding-4x-top oj-flex-bar" },
                    (0, preact_1.h)("span", { className: 'oj-flex-bar-start' }, saasUserGrade[0].toUpperCase() + saasUserGrade.slice(1).toLowerCase()),
                    (0, preact_1.h)("span", { className: "".concat(saasIconClass, " oj-flex-bar-end"), role: "img", "aria-label": "error" }))),
            (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-padding-4x oj-panel oj-md-margin-8x-start oj-sm-margin-2x-bottom" },
                (0, preact_1.h)("div", null, "Your Multiple Choice Result"),
                (0, preact_1.h)("div", { className: "oj-typography-subheading-lg oj-sm-padding-4x-top oj-flex-bar" },
                    (0, preact_1.h)("span", { className: 'oj-flex-bar-start' }, skillableUserGrade[0].toUpperCase() + skillableUserGrade.slice(1).toLowerCase()),
                    (0, preact_1.h)("span", { className: "".concat(skillableIconClass, " oj-flex-bar-end"), role: "img", "aria-label": "error" })))));
    };
    exports.default = SaasPbtExamScore;
});
