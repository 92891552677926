define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.EVENT_STATUS = exports.EnrollmentStatus = exports.TenantType = void 0;
    var TenantType;
    (function (TenantType) {
        TenantType[TenantType["OU"] = 1] = "OU";
        TenantType[TenantType["OSPA"] = 2] = "OSPA";
        TenantType[TenantType["NETSUITE"] = 3] = "NETSUITE";
        TenantType[TenantType["ACONE"] = 4] = "ACONE";
        TenantType[TenantType["CSM"] = 5] = "CSM";
        TenantType[TenantType["PRODUCT_SUPPORT"] = 6] = "PRODUCT_SUPPORT";
        TenantType[TenantType["HOSPITALITY"] = 7] = "HOSPITALITY";
    })(TenantType || (exports.TenantType = TenantType = {}));
    var EnrollmentStatus;
    (function (EnrollmentStatus) {
        EnrollmentStatus["NONE"] = "NONE";
        EnrollmentStatus["PENDING"] = "PENDING";
        EnrollmentStatus["WAITLISTED"] = "WAITLISTED";
        EnrollmentStatus["REJECTED"] = "REJECTED";
        EnrollmentStatus["APPROVED"] = "APPROVED";
        EnrollmentStatus["ENROLLMENT_CLOSED"] = "ENROLLMENT_CLOSED";
        EnrollmentStatus["ENROLLMENT_FULL"] = "ENROLLMENT_FULL";
        EnrollmentStatus["ERROR"] = "ENROLLMENT_ERROR";
        EnrollmentStatus["CANCELLED"] = "CANCELLED";
        EnrollmentStatus["ATTENDED"] = "ATTENDED";
        EnrollmentStatus["UNENROLLED"] = "UNENROLLED";
    })(EnrollmentStatus || (exports.EnrollmentStatus = EnrollmentStatus = {}));
    ;
    var EVENT_STATUS;
    (function (EVENT_STATUS) {
        EVENT_STATUS["EVENT_CANCELLED"] = "event_cancelled";
        EVENT_STATUS["ENROLLMENT_CLOSED"] = "enrollment_closed";
        EVENT_STATUS["EVENT_COMPLETE"] = "event_complete";
        EVENT_STATUS["EVENT_FREE"] = "event_free";
        EVENT_STATUS["EVENT_NO_ACCESS"] = "event_no_access";
        EVENT_STATUS["EVENT_ACCESS"] = "event_access";
    })(EVENT_STATUS || (exports.EVENT_STATUS = EVENT_STATUS = {}));
});
