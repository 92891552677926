define(["require", "exports", "preact", "./index"], function (require, exports, preact_1, index_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var DialogComponent = function (props) {
        var _a, _b, _c, _d;
        var type = props.type, dialogBtnTitle = props.dialogBtnTitle, dialogBtnDesc = props.dialogBtnDesc, header = props.header, body = props.body, footer = props.footer, onClickAction = props.onClickAction, _e = props.onBeforeClose, onBeforeClose = _e === void 0 ? function () { } : _e, onCloseDialog = props.onCloseDialog, more = props.more;
        var openDialog = function () {
            if (!onClickAction) {
                document.getElementById(type + 'ModalDialog').open();
            }
            else {
                onClickAction();
            }
        };
        var closeDialog = function () {
            if (onBeforeClose)
                onBeforeClose();
            if (!onCloseDialog) {
                document.getElementById(type + 'ModalDialog').close();
            }
            else {
                onCloseDialog();
            }
        };
        return ((0, preact_1.h)("div", { className: "".concat(type, "-component oj-flex copyParentHeightWidth"), key: "".concat(type + '-' + ((_a = props.more) === null || _a === void 0 ? void 0 : _a.id)) },
            body &&
                (0, preact_1.h)(index_1.ModalDialogComponent, { className: "oj-flex-item", modalIdPrefix: "".concat(type, "-").concat((_b = props.more) === null || _b === void 0 ? void 0 : _b.id), key: "".concat(type, "-").concat((_c = props.more) === null || _c === void 0 ? void 0 : _c.id), type: type, header: header, body: body, footer: footer, onBeforeClose: closeDialog }),
            (0, preact_1.h)(index_1.CourseComponentsPane, { className: "oj-flex-item", key: "".concat(type, "-").concat((_d = props.more) === null || _d === void 0 ? void 0 : _d.id), type: type, dialogBtnTitle: dialogBtnTitle, dialogBtnDesc: dialogBtnDesc, onClickAction: openDialog, more: more })));
    };
    exports.default = DialogComponent;
});
