define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.InputMessageDTO = exports.InputMessageType = void 0;
    var InputMessageType;
    (function (InputMessageType) {
        InputMessageType["NONE"] = "none";
        InputMessageType["CONSUMPTION_UPDATE"] = "consumption_update";
    })(InputMessageType || (exports.InputMessageType = InputMessageType = {}));
    var InputMessageDTO = /** @class */ (function () {
        function InputMessageDTO() {
            this.msgType = InputMessageType.NONE;
            this.emitedAt = new Date();
        }
        return InputMessageDTO;
    }());
    exports.InputMessageDTO = InputMessageDTO;
});
